<template>
    <div>
        <component
            :key="id"
            :obj-id="inputObj.id"
            :is="inputType"
            :list="choices"
            :options="options"
            v-model="inputValue"
            :initial-value="initialValue"
            :current-theme="currentTheme"
            :disabled="disabled"/>
    </div>
</template>

<script>
import { uuid } from 'vue-uuid';
export default {
    name: 'inputWrapper',
    components: {
    },
    data () {
        return {
            inputValue: ''
        };
    },
    props: {
        inputObj: {},
        value: {},
        disabled: {
            type: Boolean,
            default: false
        },
        initialValue: {}
    },
    computed: {
        currentTheme () {
            return this.$store.state.currentTheme;
        },
        id () {
            return "component-" + this.inputObj.id;
        },
        inputType () {
            return this.inputObj.config.inputType;
        },
        options () {
            return this.inputObj.config;
        },
        choices () {
            return this.inputObj.config.hasOwnProperty("choices") ? this.options.choices : [];
        }
    },
    watch: {
        inputValue (newValue) {
            if (newValue.hasOwnProperty('inputType')) {
                this.$emit('input-val-update', {
                    questionId: newValue.questionId,
                    inputType: newValue.inputType,
                    value: newValue.value, id: uuid.v4()
                });
            } else {
                this.$emit('input-val-update', {questionId: this.inputObj.id, inputType: this.inputObj.config.inputType, value: newValue, id: uuid.v4()});
            }
        },
        id () {
            this.options.choices !== undefined ? this.choices = this.options.choices : '';
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
