/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store.js";
import axios from "axios";
import dayjs from 'dayjs';
import { auth, HELLOJSLOCALSIGNINPOLICYNAME, HELLOJSLCPSIGNINPOLICYNAME, HELLOJSREGISTERPOLICYNAME } from "@/services/authentication.js";

Vue.use(Router);

let loadView = view => {
    return () => import(`@/components/pages/${view}.vue`);
};

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/questionsets",
            component: loadView("questionSetList"),
            props: true,
            meta: {
                storeCallPre: "getUser",
                name: "questionSets",
                requiresAuth: true
            }
        },
        {
            path: "/",
            component: loadView("welcome"),
            props: true,
            meta: {
                name: "welcome"
            }
        },
        {
            path: "/welcome",
            component: loadView("welcome"),
            props: true,
            meta: {
                name: "welcome"
            }
        },
        {
            path: "/questionset/:org/:id",
            component: loadView("Home"),
            props: true,
            meta: {
                storeCallPre: "loadQuestions",
                name: "QuestionsPage",
                requiresAuth: true
            }
        },
        {
            path: "/edit/questionset/:org/:id/:sectionID",
            component: loadView("Home"),
            props: true,
            name: "editMode",
            meta: {
                storeCallPre: "loadQuestions",
                name: "QuestionsPage",
                requiresAuth: true
            }
        },
        {
            path: "/questionset/:org/:id/:sectionID",
            component: loadView("Home"),
            props: true,
            meta: {
                storeCallPre: "loadQuestions",
                name: "QuestionsPage",
                requiresAuth: true
            }
        },
        {
            path: "/questionset/:org/:id/:sectionID/:questionID",
            component: loadView("Home"),
            props: true,
            meta: {
                storeCallPre: "loadQuestions",
                name: "QuestionsPage",
                requiresAuth: true
            }
        },
        {
            path: "/approval",
            name: "approval",
            component: loadView("approval"),
            meta: {
                name: "approval"
            }
        },
        {
            path: "/manage/activityLog",
            name: "activityLog",
            component: loadView("manage"),
            meta: {
                name: "activityLog"
            },
            beforeEnter: (to, from, next) => {
                if (store.state.user.isCollator) {
                    next();
                } else {
                    router.push('/');
                }
            },
        },
        {
            path: "/manage/referrals",
            name: "referrals",
            component: loadView("manage"),
            meta: {
                name: "referrals"
            },
            beforeEnter: (to, from, next) => {
                if (store.state.user.isCollator && !store.state.organisation.readOnly) {
                    next();
                } else {
                    router.push('/');
                }
            },
        },
        {
            path: "/manage/highlights",
            name: "highlights",
            component: loadView("manage"),
            meta: {
                name: "highlights"
            },
            beforeEnter: (to, from, next) => {
                if (store.state.user.isCollator && !store.state.organisation.readOnly) {
                    next();
                } else {
                    router.push('/');
                }
            },
        },
        {
            path: "/manage/reorder-questions",
            name: "reorderQuestions",
            component: loadView("manage"),
            meta: {
                name: "reorderQuestions"
            },
            beforeEnter: (to, from, next) => {
                if (store.state.user.isCollator && !store.state.organisation.readOnly) {
                    next();
                } else {
                    router.push('/');
                }
            },
        },
        {
            path: "/404",
            name: "404",
            component: loadView("page404")
        },
        {
            path: "/500",
            name: "500",
            component: loadView("page500"),
            props: true
        },
        {
            path: "/error",
            name: "error",
            component: loadView("errorView"),
            props: true
        }
    ]
});
router.beforeResolve((to, from, next) => {
    //***** authentication code ******//
    if (to.name === "500" || to.name === "404" || to.name === "error" ) {
        next();
    } else {

        if (to.meta.name === "welcome") {
            auth.init();
            const isOnline = auth.online(HELLOJSLOCALSIGNINPOLICYNAME) || auth.online(HELLOJSREGISTERPOLICYNAME);
            const isLcpOnline = auth.online(HELLOJSLCPSIGNINPOLICYNAME);

            if (isOnline || isLcpOnline) {
                router.push("/questionsets");
                return;
            }
        }
        let authResponse = localStorage.getItem("hello");

        if (!store.getters["auth/getAuthState"]) {
            if (authResponse && authResponse !== "{}") {
                var authObject = JSON.parse(authResponse);
                if (authObject.hasOwnProperty("localSignIn")) {
                    authObject = authObject.localSignIn;
                } else if (authObject.hasOwnProperty("lcpSignIn")) {
                    authObject = authObject.lcpSignIn;
                } else if (authObject.hasOwnProperty("signUp")) {
                    authObject = authObject.signUp;
                } else {
                    localStorage.removeItem("hello");
                    return;
                }

                if (dayjs.unix(authObject.expires) > dayjs()) {
                    store.commit("auth/setAuthState", authObject);
                    axios.defaults.headers.common["Authorization"] = "Bearer " + authObject.access_token;
                    if (authObject.state === "lcpSignIn") {
                        store.commit("auth/setIsLcpLogin", true);
                        store.commit("auth/setIsLocalLogin", false);
                    } else if (authObject.state === "localSignIn") {
                        store.commit("auth/setIsLcpLogin", false);
                        store.commit("auth/setIsLocalLogin", true);
                    }
                } else {
                    // clear localstorage, redirect
                    localStorage.removeItem("hello");
                    store.commit("auth/setAuthState", null);
                }
            } else if (authResponse && authResponse === "{}") {
                // clear localstorage, redirect
                localStorage.removeItem("hello");
                store.commit("auth/setAuthState", null);
            }
        }
        //***** end auth code ******//

        if (!store.getters["auth/getAuthState"] && to.meta.name !== "welcome") {
            next({ path: "/" });
            return;
        } else if (store.state.user.role === "client" && to.meta.name === "editMode") {
            next({ path: "questionsets" });
            return;
        } else {
            to.meta.name === undefined && (to.meta.name === undefined || to.meta.name === null) ? router.push("404") : "";
            if (to.meta.storeCallPre !== undefined) {
                if (to.meta.storeCallPost !== undefined) {
                    store.dispatch(to.meta.storeCallPre).then(() => {
                        next(store.dispatch(to.meta.storeCallPost, to.params.id));
                    });
                } else {
                    if (to.meta.storeCallPre == "loadQuestions") {
                        store.dispatch(to.meta.storeCallPre, to.params.id).then(() => {
                            store.dispatch("updateSelectedSectionKey", store.state.selectedSectionKey === null ? [0] : [store.state.selectedSectionKey]).then(() => {
                                next();
                            });
                        });
                    } else {
                        store.dispatch(to.meta.storeCallPre, to.params.id).then(() => {
                            next();
                        });
                    }
                }
            } else {
                next();
            }
        }
    }
});

export default router;
