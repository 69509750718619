<template>
    <v-popover class="allow-organisation">
        <slot name="button"></slot>
        <template slot="popover">
            <div class="contactCard condensed" :class="$store.state.usersQuestionnaires[questionnaireId].theme">
                <div class="popup-header">{{ title }}</div>
                <div class="popup-list">
                    <div class="org-names" v-for="(org, key) in $store.state.allOrganisations" :key="key">
                        <div class="" v-if="!org.questionSets.includes(questionnaireId) && org.organisationAKA !== 'LCP'">
                            <div class="org-initial">{{ org.organisationAKA[0] }}</div>
                            <div class="org-name">{{ org.organisationAKA }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </v-popover>
</template>

<script>
import VTooltip from "v-tooltip";
export default {
    name: "popover",
    component: {
        VTooltip
    },
    props: {
        title: {
            type: String,
            validator: function(value) {
                if (typeof value !== "string") {
                    Vue.util.warn("Invalid value: Value should be string.");
                    return false;
                } else {
                    return true;
                }
            }
        },
        questionnaireId: {
            type: String,
            validator: function(value) {
                if (typeof value !== "string") {
                    Vue.util.warn("Invalid value: Value should be string.");
                    return false;
                } else {
                    return true;
                }
            }
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";
.section {
    display: inline-block;
}
.condensed {
    .popup-header {
        color: $grey;
        font-size: 14px;
        padding: 10px 10px 0px;
    }
    .popup-list {
        font-size: 14px;
        height: auto;
        margin-right: 10px;
        overflow: auto;
        .org-names {
            cursor: pointer;
            padding-bottom: 0px;
            padding-left: 10px;
            padding: 10px;
            .org-initial {
                border-radius: 100%;
                color: white;
                display: inline-block;
                height: 32px;
                margin: -1px;
                overflow: hidden;
                padding-right: 0;
                padding-top: 8px;
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 32px;
            }
            .org-name {
                display: inline-block;
                padding: 10px;
            }
        }
    }
}
.allow-organisation {
    display: inline-block;
    margin: 5px;
    position: relative;
    .btn {
        margin: 0;
    }
}
.btn {
    border: none;
    color: white;
    cursor: pointer;
    display: inline-block;
    margin: 5px;
    min-height: 34px;
    min-width: 80px;
    padding: 0px 15px 0px 15px;
}
</style>
