<template>
    <div>
        <div class="input-holder">
            <text-input
                @input="updateVal"
                @enter-clicked="addChoice"
                :initialValue="initalValues"
                :options="{'characterLimit': 1000}"
                fullWidth
                :action="{'text': 'add'}"/>
        </div>
        <div class="choices">
            <div v-for="(choice, key) in choices" :key="key" class="choice">
                <span class="choiceSpan">{{choice.text}}</span>
                <span class="icon icon-bin" @click="removeChoice(choice)" v-tooltip="'Remove selected choice'"></span>
            </div>
            <div v-if="choices.length === 0" class="no-choices">No choices</div>
        </div>
    </div>
</template>

<script>
import textInput from '@/components/common/inputs/textInput';
export default {
    name: 'choicesSelector',
    props: {
        initialChoices: {
            type: Array,
            default: () => []
        },
        questionConfig: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        textInput,
    },
    data () {
        return {
            choiceIdTotal: 0,
            choices: [],
            currentVal: '',
            initalValues: {text: ''}
        };
    },
    created () {
        if (!this.questionConfig.hasOwnProperty('choiceIdTotal')) {
            this.choiceIdTotal = 0;
        } else {
            this.choiceIdTotal = this.questionConfig.choiceIdTotal;
        }
        this.choices = this.initialChoices !== '' ? this.initialChoices : [];
    },
    methods: {
        updateVal (val) {
            this.currentVal = val;
        },
        addChoice () {
            this.choiceIdTotal ++;
            this.choices.push({choiceId: this.choiceIdTotal,'text': this.currentVal});
            this.currentVal = '';
            this.initalValues = {choiceId: this.choices.length + 1, text: ''};
            this.$emit('choice-list', {'choiceIdTotal': this.choiceIdTotal ,'choices': this.choices});
        },
        removeChoice (choice) {
            const index = this.choices.indexOf(choice);
            index !== -1 ? this.choices.splice(index, 1) : '';
            this.$emit('choice-list', this.choices);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.input-holder {
    float: left;
    width: 100%;
}
.choices {
    float: left;
    margin: 10px 0;
    width: 100%;
    .choice {
        border-radius: 2px;
        border: solid 1px $grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 10px 10px 0;
        width: 100%;
        padding: 12px;
        z-index: 1;
        .choiceSpan {
            display: inline-block;
            max-width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .icon-bin {
            display: inline-block;
            color: $lcpWatermelon;
            cursor: pointer;
            margin-left: 5px;
        }
    }
    .no-choices {
        background-color: #e6e6e6;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
    }
}
</style>
