<template>
    <div class="input-checkbox--div">
        <div class="input-style checkbox">
            <div class="text-choices" v-if="showLabel">Please select all that apply:</div>
            <div v-for="(choice, key) in list" :key="choice.id">
                <div v-if="selectedList.length > 1">
                    <div class="checkbox-buttons" :style="{borderColor: currentTheme, backgroundColor: getThemeColor(key)}" :class="{'selected-checkbox': selectedList[key].choice, 'disabled': disabled }" @click="checkList(key)">
                        <div class="label">{{choice.text}}</div>
                        <div class="checkbox-icon" :style="{borderColor: currentTheme}">
                            <span class="icon icon-checkmark" v-if="selectedList[key].choice" :style="{color: currentTheme}"></span>
                        </div>
                    </div>
                </div>
                <div v-if="selectedList.length === 1">
                    <!-- <div class="checkbox-buttons checkbox-button" :style="{borderColor: currentTheme, backgroundColor: getThemeColor(key)}" :class="{'selected-checkbox': selectedList[key].choice, 'disabled': disabled }" > -->
                        <div class="checkbox-icon-single" :style="{borderColor: currentTheme}" @click="checkList(key)">
                            <span class="icon icon-checkmark" v-if="selectedList[key].choice" :style="{color: currentTheme}"></span>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    name: 'checkboxInput',
    props: {
        list: {
            type: Array,
            validator: function (value) {
                if (!Array.isArray(value)) {
                    Vue.util.warn('Invalid value: Value should be Array.');
                    return false;
                } else {
                    return true;
                }
            },
            required: true
        },
        name: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        initialValue: {
            default: () => []
        },
        currentTheme: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            radioValue: null,
            selectedList: []
        };
    },
    created  () {
        if (this.initialValue !== '' && this.initialValue.length > 0) {
            for (let i = 0; i < this.list.length; i++) {
                let choice = this.initialValue.findIndex(a => a.choiceId === this.list[i].choiceId) > -1 ? true : false;
                this.selectedList.push({
                    choice: choice,
                    choiceId: this.list[i].choiceId,
                    value: this.list[i].text
                });
            }
        } else {
            for (let j = 0; j < this.list.length; j++) {
                this.selectedList.push({
                    choice: false,
                    choiceId: this.list[j].choiceId,
                    value: this.list[j].text
                });
            }
        }
    },
    methods: {
        checkList (key) {
            if (!this.disabled) {
                this.$set(this.selectedList, key, {
                    choice: !this.selectedList[key].choice,
                    choiceId: this.list[key].choiceId,
                    value: this.list[key].text
                });
                var result = [];
                for (let choices = 0; choices < this.selectedList.length; choices++) {
                    if (this.selectedList[choices].choice) {
                        result.push({choiceId: this.selectedList[choices].choiceId, value: this.selectedList[choices].value});
                    }
                }
                this.$emit('input', result);
            }
        },
        getThemeColor (key) {
            return this.selectedList[key] ? this.currentTheme : '';
        },
    },
    watch: {
        list () {
            if (this.initialValue !== '') {
                for (let i = 0; i < this.list.length; i++) {
                    let choice = this.initialValue.choiceId === this.list[i].choiceId ? true : false;
                    this.selectedList.push({
                        choice: choice,
                        choiceId: this.list[i].choiceId,
                        value: this.list[i].text
                    });
                }
            } else {
                for (let j = 0; j < this.list.length; j++) {
                    this.selectedList.push({
                        choice: false,
                        choiceId: this.list[j].choiceId,
                        value: this.list[j].text
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
.input-checkbox--div {
    display: inline-block;
    height: auto;
    position: relative;
    .input-style {
        display: inline-block;
        position: relative;
            &.checkbox {
            display: inline-block;
            position:relative;
            .text-choices {
                padding-bottom: 20px;
            }
            .checkbox-buttons {
                border-radius: 3px;
                border: 2px solid $lcpWatermelon;
                cursor: pointer;
                display: inline-block;
                font-size: 1.1rem;
                line-height: 1.3rem;
                margin-right: 20px;
                padding: 15px;
                position: relative;
                transition-duration: 1s;
                transition-property: border, box-shadow, background;
                transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                width: 100%;
                margin-bottom: 20px;
                &.selected-checkbox {
                    background: $lcpWatermelon;
                    color: white;
                }
            }
            .checkbox-button {
                padding: 10px;
            }
            .checkbox-icon {
                background: white;
                border-radius: 3px;
                border: 2px solid $lcpWatermelon;
                color: $lcpWatermelon;
                font-size: 1.3rem;
                height: 25px;
                position: absolute;
                right: -12.5px;
                top: -12.5px;
                width: 25px;
            }
            .checkbox-icon-single {
                background: white;
                border-radius: 3px;
                border: 2px solid $lcpWatermelon;
                color: $lcpWatermelon;
                cursor: pointer;
                font-size: 1.3rem;
                height: 25px;
                width: 25px;
            }
            .disabled {
                border: 2px solid rgb(235, 235, 228)!important;
                cursor: default!important;
                .checkbox-icon {
                    border: 2px solid rgb(235, 235, 228)!important;
                }
            }
            input {
                width: 8%;
            }
            label {
                width: 91%;
            }
            .container {
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;
                cursor: pointer;
                margin-bottom: 12px;
                margin-right: 10px;
                padding-left: 35px;
                padding-top: 5px;
                position: relative;
                user-select: none;
                input {
                    &:checked ~ .checkmark {
                        &:after {
                            display: block;
                        }
                    }
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                }
                .checkmark {
                    background: #f5f5f5;
                    border-radius: 1px;
                    height: 25px;
                    left: 0;
                    padding: 10px;
                    position: absolute;
                    top: 0;
                    width: 25px;
                    &:after {
                        background: $lcpDarkBlue;
                        border-radius: 1px;
                        content: "";
                        display: none;
                        height: 12px;
                        left: 50%;
                        margin-left: -6px;
                        margin-top: -6px;
                        position: absolute;
                        top: 50%;
                        width: 12px;
                    }
                }
            }
            }
    }
}
</style>
