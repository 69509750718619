<template>
    <div class="input-list-wrapper">
        <text-input
            @input="updateVal"
            @enter-clicked="addItem"
            :initialValue="initalValues"
            :options="{ limit: 500 }"
            fullWidth
            :action="{ text: 'add' }"
            :disabled="disabled"
        />
        <div v-for="(list, key) in list" :key="key" class="list-wrapper">
            <div class="list-text">{{ list.text }}</div>
            <div class="buttons-wrapper">
                <div class="list-button" @click="removelist(key)">
                    <span class="icon icon-bin"></span>
                </div>
                <div class="list-button" @click="editlist(key)">
                    <span class="icon icon-pencil"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "listInput",
    props: {
        initialValue: {},
        disabled: {
            default: false
        },
        options: {}
    },
    data() {
        return {
            currentVal: "",
            initalValues: { text: "" },
            list: []
        };
    },
    created() {
        this.list = this.initialValue ?? [];
    },
    methods: {
        updateVal(val) {
            this.currentVal = val;
        },
        addItem() {
            if (!this.disabled && this.currentVal.text !== "") {
                this.list.push({ text: this.currentVal });
                this.currentVal = "";
                this.initalValues = { text: "" };
                this.$emit("input", this.list);
            }
        },
        removelist(key) {
            this.list.splice(key, 1);
            this.$emit("input", this.list);
        },
        editlist(key) {
            this.initalValues = { text: this.list[key].text };
            this.currentVal = this.list[key].text;
            this.list.splice(key, 1);
            this.$emit("input", this.list);
        }
    },
    computed: {
        characterLimit() {
            if (this.options !== null && typeof this.options !== "undefined" && this.options.hasOwnProperty("limit")) {
                return this.options.limit;
            } else {
                return 150;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/all.scss";
.input-list-wrapper {
    display: block;
    position: relative;
    width: 50%;
    .input-wrapper {
        margin-bottom: 20px;
    }
    .list-text {
        border-radius: 3px;
        border: 2px solid #e6e6e6;
        display: inline-block;
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 20px;
        margin-right: 10px;
        padding: 10px;
        position: relative;
        transition-duration: 1s;
        transition-property: border, box-shadow, background;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        width: calc(80% - 10px);
    }
    .buttons-wrapper {
        float: right;
        width: calc(20% - 10px);
        .list-button {
            border-radius: 2px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            color: #fff;
            cursor: pointer;
            display: block;
            float: right;
            height: 39px;
            margin-left: 10px;
            padding: 10px;
            position: relative;
            text-align: center;
            z-index: 1;
            .icon-bin {
                color: #fff;
            }
        }
    }
}
</style>
