<template>
    <div class="select-wrapper">
        <multiselect
            :allowEmpty="true"
            :disabled="disabled"
            :key="objId"
            :options="listValues()"
            :placeholder="placeholderText"
            :show-labels="false"
            @remove="dispatchAction"
            @select="dispatchAction"
            v-model="inputData"
        />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
export default {
    name: 'selectInput',
    props: {
        list: {
            type: Array,
            validator (value) {
                if (!Array.isArray(value)) {
                    Vue.util.warn('Invalid value: Value should be Array.');
                    return false;
                } else {
                    return true;
                }
            },
            required: true
        },
        initialValue: {},
        placeholderText: {
            type: String,
            default: 'Select a value'
        },
        disabled: {},
        objId: {}
    },
    components: {
        Multiselect
    },
    data () {
        return {
            inputData: '',
            selectedList: []
        };
    },
    created () {
        this.inputData = this.initialValue !== undefined ? Object.keys(this.initialValue).length !== 0 ? this.initialValue.value : '' : '';
        for (let j = 0; j < this.list.length; j++) {
            let condition = this.list[j].hasOwnProperty('conditional') ? this.list[j].conditional : [];
            this.selectedList.push({
                choice: false,
                choiceId: this.list[j].choiceId,
                condition: false,
                conditional: condition,
                value: this.list[j].text
            });
        }
    },
    methods: {
        listValues () {
            return this.list.map(x => x.text);
        },
        dispatchAction (inputVal) {
            if (this.disabled) return;

            const result = [];

            if (this.inputData !== null) {
                for (let choices = 0; choices < this.selectedList.length; choices++) {
                    if (inputVal === this.selectedList[choices].value) {
                        if (Object.keys(this.selectedList[choices].conditional).length > 0) {
                            result.push({choiceId: this.selectedList[choices].choiceId, value: this.selectedList[choices].value});
                        } else {
                            result.push({choiceId: this.selectedList[choices].choiceId, value: this.selectedList[choices].value});
                        }
                    }
                }
            }
            this.$emit('input', result);
        }
    }
};
</script>

<style lang="scss" scoped>
    .select-wrapper {
        margin-bottom: 20px;
        width: 50%;
    }
</style>
