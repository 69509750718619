<template>
    <div class="input-radio--div">
        <div class="input-style radio">
            <div class="text-choices" v-if="showLabel">{{ label }}</div>
            <div v-for="(choice, key) in list" :key="choice.id" class="choice-wrapper">
                <div v-if="selectedList.length !== 0">
                    <div class="radio-buttons" :style="{borderColor: currentTheme, backgroundColor: getThemeColor(key)}" :class="{ 'selected-radio': selectedList[key].choice, 'disabled': disabled }" @click="radioList(key)">
                        <div class="label">{{choice.text}}</div>
                        <div class="radio-icon" :style="{borderColor: currentTheme}">
                            <span class="icon icon-checkmark" v-if="selectedList[key].choice" :style="{color: currentTheme}"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import radioSelected from "@/assets/svg/radio-selected.svg";
import radioUnselected from "@/assets/svg/radio-unselected.svg";
export default {
    name: 'radioInput',
    props: {
        list: {
            type: Array,
            validator: function (value) {
                if (!Array.isArray(value)) {
                    Vue.util.warn('Invalid value: Value should be Array.');
                    return false;
                } else {
                    return true;
                }
            },
            required: true
        },
        radioName: {
            type: String,
            default: "",
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        initialValue: {},
        currentTheme: {
            type: String
        },
        disabled: {},
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Please select ONE from the following choices:'
        }
    },
    data () {
        return {
            radioSelected: radioSelected,
            radioUnselected: radioUnselected,
            radioValue: null,
            selectedList: []
        };
    },
    created () {
        if (this.initialValue !== '' && this.initialValue !== undefined) {
            for (let i = 0; i < this.list.length; i++) {
                let choice = this.initialValue[0].choiceId === this.list[i].choiceId ? true : false;
                this.selectedList.push({
                    choice: choice,
                    choiceId: this.list[i].choiceId,
                    value: this.list[i].text
                });
            }
        } else {
            for (let j = 0; j < this.list.length; j++) {
                this.selectedList.push({
                    choice: false,
                    choiceId: this.list[j].choiceId,
                    value: this.list[j].text
                });
            }
        }
    },
    methods: {
        getThemeColor (option) {
            return this.radioValue === option ? this.currentTheme : '';
        },
        radioList (key) {
            if (!this.disabled) {
                for (let choiceKey = 0; choiceKey < this.selectedList.length; choiceKey++) {
                    if (choiceKey === key) {
                        this.$set(this.selectedList, choiceKey, {
                            choice: true,
                            choiceId: this.list[choiceKey].choiceId,
                            value: this.list[choiceKey].text
                        });
                    } else {
                        this.$set(this.selectedList, choiceKey, {
                            choice: false,
                            choiceId: this.list[choiceKey].choiceId,
                            value: this.list[choiceKey].text
                        });
                    }
                }
                var result = [];
                for (let choices = 0; choices < this.selectedList.length; choices++) {
                    if (this.selectedList[choices].choice) {
                        result.push({choiceId: this.selectedList[choices].choiceId, value: this.selectedList[choices].value});
                    }
                }
                this.$emit('input', result);
            }
        }
    },
    watch: {
        initialValue (value) {
            this.radioValue = value;
        },
        list () {
            if (this.initialValue !== '') {
                for (let i = 0; i < this.list.length; i++) {
                    let choice = this.initialValue.choiceId === this.list[i].choiceId ? true : false;
                    this.selectedList.push({
                        choice: choice,
                        choiceId: this.list[i].choiceId,
                        value: this.list[i].text
                    });
                }
            } else {
                for (let j = 0; j < this.list.length; j++) {
                    this.selectedList.push({
                        choice: false,
                        choiceId: this.list[j].choiceId,
                        value: this.list[j].text
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
.input-radio--div {
    display: inline-block;
    height: auto;
    position: relative;
    .input-style {
        display: inline-block;
        position: relative;
        .choice-wrapper {
            display: inline-block;
        }
        &.radio {
        display: inline-block;
        position:relative;
            .text-choices {
                padding-bottom: 20px;
            }
            .radio-buttons {
                border-radius: 3px;
                border: 2px solid $lcpWatermelon;
                cursor: pointer;
                display: inline-block;
                font-size: 1.1rem;
                line-height: 1.3rem;
                margin-right: 20px;
                max-width: 350px;
                padding: 15px;
                position: relative;
                transition-duration: 1s;
                transition-property: border, box-shadow, background;
                transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                width: auto;
                &.selected-radio {
                    background: $lcpWatermelon;
                    color: white;
                }
                img {
                    height: 1.4rem;
                    vertical-align: bottom;
                    width: 1.4rem;
                }
            }
            .radio-icon {
                background: white;
                border-radius: 50%;
                border: 2px solid $lcpWatermelon;
                color: $lcpWatermelon;
                font-size: 1.3rem;
                height: 25px;
                position: absolute;
                right: -12.5px;
                top: -12.5px;
                width: 25px;
            }
            .disabled {
                border: 2px solid rgb(235, 235, 228)!important;
                cursor: default!important;
                .radio-icon {
                    border: 2px solid rgb(235, 235, 228)!important;
                }
            }
            input {
                width: 8%;
            }
            label {
                width: 91%;
            }
            .container {
                img {
                    height: 1.4rem;
                    vertical-align: bottom;
                    width: 1.4rem;
                }
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;
                cursor: pointer;
                margin-right: 10px;
                position: relative;
                user-select: none;
                width: 15%;
                input {
                    &:checked ~ .radiomark {
                        &:after {
                            display: block;
                        }
                    }
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                }
            }
        }
    }
}
</style>
