<template>
    <div>
        <component :is="inputType" :list="choices" :options="options" v-model="inputValue" :initial-value="value" :current-theme="currentTheme" :last-date="isLastIndex" />
    </div>
</template>

<script>
// import Vue from 'vue';
import textInput from '@/components/common/inputs/textInput';
import dateInput from '@/components/common/inputs/dateInput';
import radioInput from '@/components/common/inputs/radioInput';
import checkboxInput from '@/components/common/inputs/checkboxInput';
import selectInput from '@/components/common/inputs/selectInput';
import textareaInput from '@/components/common/inputs/textareaInput';
import uploadInput from '@/components/common/inputs/uploadInput';
import bioInput from '@/components/common/inputs/bioInput';
import tableInput from '@/components/common/inputs/tableInput';

export default {
    name: 'inputWrapper',
    components: {
        textInput,
        textareaInput,
        radioInput,
        checkboxInput,
        selectInput,
        dateInput,
        uploadInput,
        bioInput,
        tableInput
    },
    data () {
        return {
            inputType: null,
            choices: [],
            options: null,
            inputValue: ''
        };
    },
    props: ['id', 'value'],
    created () {
        const question = this.$store.state.questionnaireQuestions[this.id];
        this.inputType = question.inputType;
        this.choices = question.choices;
        question.options !== undefined ? this.options = question.options : '';
    },
    computed: {
        currentTheme () {
            return this.$store.state.currentTheme;
        },
        isLastIndex () {
            return this.$store.state.questionSet.questions[this.$store.state.selectedSectionKey].questions[this.$store.state.questionSet.questions[this.$store.state.selectedSectionKey].questions.length-1].questionID === this.id;
        }
    },
    watch: {
        id (newValue) {
            const question = this.$store.state.questionSet[newValue];
            this.inputType = question.inputType;
            this.choices = question.choices;
            question.options !== undefined ? this.options = question.options : '';
        },
        inputValue (newValue) {
            this.$emit('input', newValue);
        }
    }
};
</script>
<style lang="scss" scoped>

</style>

