<template>
    <div class="input-table--div">
        <div class="table-input" :class="{ disabled: disabled }">
            <div class="rowGroup" v-if="options.columnHeaders !== ''">
                <div class="tr">
                    <div class="td head" v-for="(col, key) in options.columnHeaders" :key="key">{{ col.text }}</div>
                </div>
            </div>
            <div class="rowGroup">
                <div class="tr" v-for="(rc, keyR) in rows" :key="keyR">
                    <div class="td" v-for="(row, keyC) in options.rowsConfig[rc]" :key="keyC">
                        <div v-if="questions[options.rowsConfig[rc][keyC]].config.type === 'label'" class="row-header">
                            {{ questions[options.rowsConfig[rc][keyC]].title }}
                        </div>
                        <div v-if="questions[options.rowsConfig[rc][keyC]].config.hasOwnProperty('inputType')">
                            <input
                                type="text"
                                class="input-style text"
                                :v-model="[keyR][keyC]"
                                :value="history(options.rowsConfig[rc][keyC])"
                                placeholder="Enter Text"
                                :disabled="disabled"
                                @input="emitChoices($event, keyR, keyC, 'text-input', options.rowsConfig[rc][keyC])"
                                v-on:keyup.enter="$emit('enter-clicked', inputValue)"
                                v-if="questions[options.rowsConfig[rc][keyC]].config.inputType === 'text-input'"
                            />
                            <textarea-input
                                v-model="[keyR][keyC]"
                                :initial-value="history(options.rowsConfig[rc][keyC])"
                                :key="[keyR][keyC]"
                                :fullWidth="true"
                                :disabled="disabled"
                                :options="questions[options.rowsConfig[rc][keyC]].config"
                                @input="emitChoices($event, keyR, keyC, 'textarea-input', options.rowsConfig[rc][keyC])"
                                v-if="questions[options.rowsConfig[rc][keyC]].config.inputType === 'textarea-input'"
                            />
                            <dateInput
                                :initialValue="history(options.rowsConfig[rc][keyC])"
                                @input="emitChoices($event, keyR, keyC, 'date-input', options.rowsConfig[rc][keyC])"
                                :disabled="disabled"
                                v-if="questions[options.rowsConfig[rc][keyC]].config.inputType === 'date-input'"
                            />
                            <multiselect
                                :show-labels="false"
                                :options="questions[options.rowsConfig[rc][keyC]].config.choices"
                                :disabled="disabled"
                                :placeholder="'Select a value'"
                                :key="[keyR][keyC]"
                                trackBy="choiceId"
                                label="text"
                                :value="history(options.rowsConfig[rc][keyC])"
                                @select="emitChoices($event, keyR, keyC, 'select-input', options.rowsConfig[rc][keyC])"
                                v-if="questions[options.rowsConfig[rc][keyC]].config.inputType === 'select-input'"
                            />
                            <radioInput
                                :list="questions[options.rowsConfig[rc][keyC]].config.choices"
                                v-model="questions[[keyR][keyC]]"
                                :initial-value="history(options.rowsConfig[rc][keyC])"
                                :key="[keyR][keyC]"
                                :showLabel="false"
                                @input="emitChoices($event, keyR, keyC, 'radio-input', options.rowsConfig[rc][keyC])"
                                v-if="questions[options.rowsConfig[rc][keyC]].config.inputType === 'radio-input'"
                            />
                            <checkboxInput
                                :initial-value="history(options.rowsConfig[rc][keyC])"
                                :key="[keyR][keyC]"
                                :list="questions[options.rowsConfig[rc][keyC]].config.choices"
                                :showLabel="false"
                                @input="emitChoices($event, keyR, keyC, 'checkbox-input', options.rowsConfig[rc][keyC])"
                                v-if="questions[options.rowsConfig[rc][keyC]].config.inputType === 'checkbox-input'"
                                v-model="questions[[keyR][keyC]]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import dateInput from "@/components/common/inputs/dateInput";
import multiselect from "vue-multiselect";
import checkboxInput from "@/components/common/inputs/checkboxInput";
import radioInput from "@/components/common/inputs/radioInput";

export default {
    name: "tableInput",
    components: {
        dateInput,
        multiselect,
        checkboxInput,
        radioInput
    },
    props: {
        options: {
            type: Object,
            validator(value) {
                if (typeof value !== "object") {
                    Vue.util.warn("Invalid value: Value should be Object.");
                    return false;
                } else {
                    return true;
                }
            },
            required: true
        },
        initialValue: {},
        disabled: {
            type: Boolean,
            default: false
        },
        objId: {
            type: String
        }
    },
    data() {
        return {
            tableData: []
        };
    },
    created() {
        var currentVal = this.initialValue;
        for (let row = 0; row < this.options.rows.length; row++) {
            for (let column = 0; column < this.options.columnHeaders.length; column++) {
                var result = -1;
                if (currentVal !== undefined && currentVal !== '') {
                    result = currentVal.findIndex(
                        z => z.rowId === this.options.rows[row] && z.questionId === this.options.rowsConfig[this.options.rows[row]][column]
                    );
                }
                this.tableData.push({
                    row: row,
                    column: column,
                    value: result > -1 ? currentVal[result].value : "",
                    questionId: this.options.rowsConfig[this.options.rows[row]][column],
                    rowId: this.options.rows[row]
                });
            }
        }
    },
    computed: {
        questions() {
            return this.$store.state.questionSet.questions;
        },
        answers() {
            return this.$store.state.answers;
        },
        pendingAnswers() {
            return this.$store.state.pendingAnswers;
        },
        rows() {
            let results = [];
            if (
                this.questions[this.objId].config.hasOwnProperty("rconditional") &&
                this.questions[this.objId].config.rconditional.length > 0 &&
                !this.$store.state.user.isCollator
            ) {
                for (let con = 0; con < this.questions[this.objId].config.rconditional.length; con++) {
                    let conQuest = this.questions[this.objId].config.rconditional[con].id;
                    let conChoiceId = this.questions[this.objId].config.rconditional[con].choiceId;
                    let pendingIndex = this.pendingAnswers.length > 0 ? this.pendingAnswers.findIndex(q => q.questionId === conQuest) : -1;
                    if (pendingIndex > -1) {
                        let latestAnswer = this.pendingAnswers[pendingIndex];
                        let found = latestAnswer.value.findIndex(a => a.choiceId === conChoiceId);
                        if (found > -1) {
                            for (let r = 0; r < this.questions[this.objId].config.rconditional[con].row.length; r++) {
                                results.push(this.questions[this.objId].config.rconditional[con].row[r].id);
                            }
                        }
                    }
                    let index = this.answers.length > 0 ? this.answers.findIndex(q => q.questionId === conQuest && pendingIndex === -1) : -1;
                    if (index > -1) {
                        let latestAnswer = this.answers[index].history[this.answers[index].history.length - 1];
                        let found = latestAnswer.answer.findIndex(a => a.choiceId === conChoiceId);
                        if (found > -1) {
                            for (let r = 0; r < this.questions[this.objId].config.rconditional[con].row.length; r++) {
                                results.push(this.questions[this.objId].config.rconditional[con].row[r].id);
                            }
                        }
                    }
                }
                return results;
            } else {
                return this.options.rows;
            }
        }
    },
    methods: {
        history(questionId) {
            let pendingIndex = this.pendingAnswers.length > 0 ? this.pendingAnswers.findIndex(q => q.questionId === questionId) : -1;
            if (pendingIndex > -1) {
                if (this.questions[questionId].config.inputType === "select-input") {
                    return this.pendingAnswers[pendingIndex].value[0];
                } else {
                    return this.pendingAnswers[pendingIndex].value;
                }
            } else {
                let index = this.answers.length > 0 ? this.answers.findIndex(q => q.questionId === questionId) : -1;
                if (index > -1) {
                    if (this.questions[questionId].config.inputType === "select-input") {
                        return this.answers[index].history[this.answers[index].history.length - 1].answer[0];
                    } else if (this.questions[questionId].config.inputType === "text-input") {
                        return this.answers[index].history[this.answers[index].history.length - 1].answer.text;
                    } else {
                        return this.answers[index].history[this.answers[index].history.length - 1].answer;
                    }
                } else {

                    switch (this.questions[questionId].config.inputType) {
                        case 'textarea-input':
                            return {text: ''};
                        case 'list-input':
                            return [];
                        case 'checkbox-input':
                            return [];
                        case 'radio-input':
                            return [];
                        case 'select-input':
                            return {};
                        default:
                            return '';
                    }
                }
            }
        },
        emitChoices(value, row, column, type, questionId) {
            let result = this.tableData.findIndex(z => z.row === row && z.column === column);
            if (result > -1) {
                if (type === "text-input") {
                    this.tableData[result].value = value.target.value;
                } else if (type === "date-input") {
                    this.tableData[result].value = new Date(value).getTime();
                } else {
                    this.tableData[result].value = value;
                }

                let val = type === "select-input" ? [this.tableData[result].value] : this.tableData[result].value;
                this.$emit("input", {
                    questionId: questionId,
                    value: val,
                    inputType: type
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/all.scss";
.input-table--div {
    margin-top: 1rem;
    position: relative;
    overflow-y: auto;
    .table-input {
        background-color: #fff;
        border-radius: 10px;
        width: 100%;
        display: table;
        .tableRows {
            display: table-row-group;
        }
        .head {
            border: 1px solid #e6e6e6;
            text-align: left;
        }
        .row-header {
            font-weight: bold;
        }
        .th {
            padding: 10px;
        }
        .rowGroup {
            display: table-row-group;
        }
        .tr {
            display: table-row;
        }
        .td {
            display: table-cell;
            border: 1px solid #e6e6e6;
            padding: 10px 5px;
            min-width: 100px;
            .input-style {
                background: transparent;
                margin: 0;
                position: relative;
                &.text {
                    border-bottom: 1px solid #e6e6e6;
                    border: none;
                    display: block;
                    font-size: 16px;
                    text-align: left;
                    width: 100%;
                }
                &.select {
                    -webkit-appearance: none;
                    border: none;
                    cursor: pointer;
                    padding: 5px 25px 5px 0px;
                    width: 100%;
                    .select-arrow {
                        background: $lcpWatermelon;
                        height: 10px;
                        position: absolute;
                        right: 0;
                        top: 0px;
                        width: 10px;
                    }
                    option {
                        border: 2px solid red;
                    }
                }
                margin-bottom: 0;
            }
        }
        .select-container {
            &:after {
                -ms-transform: rotate(44deg);
                -webkit-transform: rotate(44deg);
                border-bottom: 2px solid $lcpWatermelon;
                border-right: 2px solid $lcpWatermelon;
                content: "";
                height: 5px;
                margin-left: -13px;
                margin-top: 8px;
                position: absolute;
                transform: rotate(44deg);
                width: 5px;
            }
        }
    }
    .disabled {
        background-color: rgb(235, 235, 228);
        color: $grey;
        .row-header,
        th {
            font-weight: normal;
        }
    }
}
</style>
