import jwt_decode from "jwt-decode";

export default {
    namespaced: true,
    state: {
        authorisedLocalLogin: false,
        authorisedLcpLogin: false,
        authObject: null,
        decodedAuth: {}
    },
    getters: {
        getAuthState: (state) => state.authObject,
        getisLocalLogin: (state) => state.authorisedLocalLogin,
        getisLcpLogin: (state) => state.authorisedLcpLogin
    },
    actions: {
    },
    mutations: {
        setAuthState (state, auth) {
            state.authObject = auth;
            state.decodedAuth = auth ? jwt_decode(auth.access_token) : {};
        },
        setIsLocalLogin (state, value) {
            state.authorisedLocalLogin = value;
        },
        setIsLcpLogin (state, value) {
            state.authorisedLcpLogin = value;
        },
    }
};
