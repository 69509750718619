export default {
    namespaced: true,
    state: {
        AllQuestions: false,
        AllUnansweredQuestions: false,
        CollectionNav: false,
        ConditionalNav: false,
        tableConditionalNav: false,
        EditNav: false,
        EditOrganisationNav: false,
        EditSectionNav: false,
        EditSectionSetNav: false,
        HelpSection: false,
        NewQuestionnaireNav: false,
        editQuestion: false,
        fadeQuestions: false,
        fullScreenQuestion: false,
        questionsScrolled: false,
        sideNavShow: true
    },
    getters: {},
    actions: {},
    mutations: {
        updateShowEditSectionNav(state, responseData) {
            state.EditSectionNav = responseData;
        },
        updateShowEditSectionSetNav(state, responseData) {
            state.EditSectionSetNav = responseData;
        },
        updateShowEditOrganisationNav(state, responseData) {
            state.EditOrganisationNav = responseData;
        },
        updateShowEditNav(state, responseData) {
            state.EditNav = responseData;
        },
        updateShowConditionalNav(state, responseData) {
            state.ConditionalNav = responseData;
        },
        updateShowTableConditionalNav(state, responseData) {
            state.tableConditionalNav = responseData;
        },
        updateShowCollectionNav(state, responseData) {
            state.CollectionNav = responseData;
        },
        updateShowNewQuestionnaireNav(state, responseData) {
            state.NewQuestionnaireNav = responseData;
        },
        updateShowHelpSection(state, responseData) {
            state.HelpSection = responseData;
        },
        updateShowAllUnansweredQuestions(state, responseData) {
            state.AllUnansweredQuestions = responseData;
        },
        updateShowAllQuestions(state, responseData) {
            state.AllQuestions = responseData;
        },
        toggleEditSidebar(state) {
            state.EditNav = !state.EditNav;
            if (state.EditNav === false) {
                state.CollectionNav = false;
                state.ConditionalNav = false;
                state.EditSectionNav = false;
                state.EditSectionSetNav = false;
                state.EditOrganisationNav = false;
                state.NewQuestionnaireNav = false;
                state.tableConditionalNav = false;
            }
        },
        closeEditSidebar(state) {
            state.EditNav = false;
            if (state.EditNav === false) {
                state.CollectionNav = false;
                state.ConditionalNav = false;
                state.EditSectionNav = false;
                state.EditSectionSetNav = false;
                state.EditOrganisationNav = false;
                state.NewQuestionnaireNav = false;
                state.tableConditionalNav = false;
            }
        },
        editQuestion(state, responseData) {
            state.editQuestion = responseData;
        },
        updateFadeQuestions(state, responseData) {
            state.fadeQuestions = responseData;
        },
        updateFullScreenQuestion(state, responseData) {
            state.fullScreenQuestion = responseData;
        },
        updateSideNavShow(state, responseData) {
            state.sideNavShow = responseData;
        }
    }
};
