<template>
    <div class="input-textarea--div" :class="{'full-width': fullWidth}">
        <textarea
            v-model="inputValue"
            class="textarea-style"
            @focus="$emit('focussed')"
            @blur="$emit('blurred')"
            @input="emitValue($event.target.value)"
            :maxlength="characterLimit"
            :disabled="disabled"/>
        <character-count :limit="characterLimit" :charLength="inputValue.length"/>
    </div>
</template>

<script>
import Vue from 'vue';
import characterCount from '@/components/common/layout/characterCount';

export default {
    name: 'textareaInput',
    components: {characterCount},
    props: {
        initialValue: {
            type: [String, Object],
            default: () => ({text: ''}),
        },
        options: {
            type: Object,
            default: () => ({characterLimit: 150}),
            validator: function (value) {
                if (typeof value !== 'object') {
                    Vue.util.warn('Invalid value: Value should be object.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean
        }
    },
    data () {
        return {
            inputData: ''
        };
    },
    created () {
        this.inputData = Object.keys(this.initialValue).length !== 0 ? this.initialValue.text !== null ? this.initialValue.text : '' : '';
        if (this.options !== null && this.inputData !== null && this.inputData !== undefined) {
            this.inputData.length > this.options.characterLimit ? this.inputData = this.inputData.slice(0, this.options.characterLimit) : '';
        }
    },
    methods: {
        emitValue (val) {
            this.$emit('input', val);
        }
    },
    computed: {
        inputValue: {
            // getter
            get () {
                return this.inputData;
            },
            // setter
            set (newValue) {
                this.inputData = newValue;
            }
        },
        characterLimit () {
            return this.options.hasOwnProperty("characterLimit") ? this.options.characterLimit : 150;
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
    .input-textarea--div {
        width: 50%;
        &.full-width{
            width: 100%;
        }
        .textarea-style {
            background: #fff;
            border-radius: 5px;
            border: 1px solid rgba(0,0,0,0.12);
            box-shadow: 0;
            height: 75px;
            padding: 10px;
            resize: none;
            transition-duration: 0.5s;
            transition-property: all;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            width: 100%;
            &:focus {
                box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06)!important;
                height: 150px;
                max-width: 100%;
                width: 100%;
            }

            &:disabled {
                background-color: rgb(235, 235, 228);
            }
        }
        .character-limit {
            color: $grey;
            text-align: right;
            margin-top: 5px;
            font-size: 14px;
        }
    }
</style>
