import axios from 'axios';
import router from './router/router';
import store from './store/store';

axios.interceptors.response.use((response) => {
    if (response.status === 200 || response.status === 204) {
        return response;
    }
    return null;
}, (error) => {
    if (!error.response || error.response.status !== 404 || error.response.status !== 500) {
        router.push('/error');
        Promise.reject(error);
    }
    if (error.response.status === 500) {
        router.push('/500');
        Promise.reject(error);
    } else if (error.response.status === 404) {
        router.push('/404');
        Promise.reject(error);
    }
    router.push('/error');
    Promise.reject(error);
});
