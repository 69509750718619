import dayjs from 'dayjs';

function formatDate (date, dateFormat) {
    return dayjs(date).format(dateFormat);
}

function isFunction (fn) {
    if (typeof fn === "function") {
        return true;
    }
    return false;
}

function section (questionId, questionSet) {
    for (let sectionIndex = 0; sectionIndex < questionSet.sections.length; sectionIndex ++) {
        const index = questionSet.sections[sectionIndex].questions.findIndex((question) => question === questionId);
        if (index > -1) return `${sectionIndex + 1}.${index + 1}`;
    }
    return '';
}

function formattedFields (questionSet) {
    const fields  = {'Manager': 'Manager'};
    const questions = questionSet.sections.map(s => s.questions).flat();
    const questionList = {};
    const questionListAll = [];

    for (let quest = 0; quest < questions.length; quest++) {
        let q = questionSet.questions[questions[quest]];
        questionListAll.push(questionSet.questions[questions[quest]].id);
        if (q.questions.length > 0) {

            questionList[questionSet.questions[questions[quest]].id] = {
                type: 'collection',
                id: questionSet.questions[questions[quest]].id
            };

            for (let questSub = 0; questSub < q.questions.length; questSub ++) {
                Array.isArray(q.questions[questSub]) ? questionListAll.push(q.questions[questSub][0]) : questionListAll.push(q.questions[questSub]);
                if (Array.isArray(q.questions[questSub]) ) {
                    questionList[q.questions[questSub][0]] = {
                        type: 'question',
                        id: q.questions[questSub][0]
                    };
                } else {
                    questionList[q.questions[questSub]] = {
                        type: 'question',
                        id: q.questions[questSub]
                    };
                }
            }

        }

        if (q.config.inputType === 'table-input') {

            questionList[questionSet.questions[questions[quest]].id] = {
                type: 'table',
                id: questionSet.questions[questions[quest]].id
            };

            for (let r = 0; r < q.config.rows.length; r++) {
                for (let rc = 0; rc < q.config.rowsConfig[q.config.rows[r]].length; rc++) {
                    questionListAll.push(q.config.rowsConfig[q.config.rows[r]][rc]);
                    questionList[q.config.rowsConfig[q.config.rows[r]][rc]] = {
                        type: 'table-subquestion',
                        id: q.config.rowsConfig[q.config.rows[r]][rc]
                    };
                }
            }

        }

        if (!questionList[questionSet.questions[questions[quest]].id]) {
            questionList[questionSet.questions[questions[quest]].id] = {
                type: 'question',
                id: questionSet.questions[questions[quest]].id
            };
        }
    }

    for (let z = 0;  z < questionListAll.length; z++) {
        let question = questionSet.questions[questionListAll[z]];

        const description = question.description?.length > 0 && question.description !== 'description' ? `<br>${question.description} ` : '';

        if (question.config.type !== 'label') fields[`${section(question.id, questionSet)} ${question.title} <br> ${description} `] = question.id;

        if (['text-input', 'textarea-input'].includes(question.config.inputType)) {
            fields[`${section(question.id, questionSet)} ${question.title}  (char: ${question.config.characterLimit})`] = question.id;
        }

        switch (question.config.inputType + '-' + questionList[questionListAll[z]].type) {
            case 'checkbox-input-question':
                for (let c = 0; c < question.config.choices.length; c++) {
                    fields[`${section(question.id, questionSet)} Option: ${question.config.choices[c].text}`] = `${question.id}-${question.config.choices[c].choiceId}`;
                }
                break;
            case 'checkbox-input-table-subquestion':
                for (let c = 0; c < question.config.choices.length; c++) {
                    fields[`${section(question.id, questionSet)} <p>${question.title}</p> Option: ${question.config.choices[c].text}`] = `${question.id}-${question.config.choices[c].choiceId}`;
                }
                break;
            case 'radio-input-question':
                for (let c = 0; c < question.config.choices.length; c++) {
                    fields[`${section(question.id, questionSet)} Option: ${question.config.choices[c].text}`] = `${question.id}-${question.config.choices[c].choiceId}`;
                }
                break;
            case 'table-input-table':
                for (let r = 0; r < question.config.rows.length; r++) {
                    let rowId = question.config.rowsConfig[question.config.rows[r]][0];
                    let rowName = questionSet.questions[rowId].title;

                    for (let rc = 0; rc < question.config.rowsConfig[question.config.rows[r]].length; rc++) {
                        if (questionSet.questions[question.config.rowsConfig[question.config.rows[r]][rc]].config.type != 'label' ) {
                            fields[`${section(question.id, questionSet)} <br> Row: ${r + 1} ${rowName} <br> Column: ${rc + 1} ${question.config.columnHeaders[rc].text} <br> (Table Input)`] = `${question.config.rowsConfig[question.config.rows[r]][rc]}`;
                        }
                    }
                }
                break;
            case 'text-input-table-subquestion':
                fields[`${section(question.id, questionSet)} ${question.title} (char: ${question.config.characterLimit}) <br> (Table Input)`] = question.id;
                break;
            default:
                break;
        }
    }
    return {fields, questionListAll};
}

class FieldText {
    Questions;

    constructor (Questions) {
        this.Questions = Questions;
    }
}

function formattedQuestion (index, question, questionSet) {
    let fields = [];
    const description = question.description?.length > 0 && question.description !== 'description' ? `<br>${question.description} ` : '';
    switch (question.config.inputType) {
        case 'text-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} <br>(Text Input)`));
            break;
        case 'textarea-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} <br>(Text Area Input)`));
            break;
        case 'date-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} <br>(Date Input)`));
            break;
        case 'upload-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} <br>(Upload Input)`));
            break;
        case 'select-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} <br>(Select Input)`));
            for (let cIndex = 0; cIndex < question.config.choices.length; cIndex ++) {
                fields.push(new FieldText(`<b>${index} choice ${cIndex + 1}:</b> ${question.config.choices[cIndex].text} <br>(Select Input)`));
            }
            break;
        case 'radio-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} (Radio Input)`));
            for (let cIndex = 0; cIndex < question.config.choices.length; cIndex ++) {
                fields.push(new FieldText(`<b>${index} choice ${cIndex + 1}:</b> ${question.config.choices[cIndex].text} <br>(Select Input)`));
            }
            break;
        case 'checkbox-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} (Radio Input)`));
            for (let cIndex = 0; cIndex < question.config.choices.length; cIndex ++) {
                fields.push(new FieldText(`<b>${index} choice ${cIndex + 1}:</b> ${question.config.choices[cIndex].text} <br>(Select Input)`));
            }
            break;
        case 'table-input':
            fields.push(new FieldText(`<b>${index}</b> ${question.title} - ${description} (Table Input)`));
            for (let rIndex = 0; rIndex < question.config.rows.length; rIndex ++) {
                for (let rcIndex = 0; rcIndex < question.config.rowsConfig[question.config.rows[rIndex]].length; rcIndex ++) {
                    const tableIndex = `${index} Row ${rIndex + 1} Column ${rcIndex + 1}`;
                    const tableQuestion = questionSet.questions[question.config.rowsConfig[question.config.rows[rIndex]][rcIndex]];

                    if (tableQuestion.config.type === 'label') {
                        fields.push(new FieldText(`<b>${tableIndex}</b> ${tableQuestion.title} - ${description} <br> (Label)`));
                    } else {
                        formattedQuestion(tableIndex, tableQuestion, questionSet);
                    }
                }
            }
            break;
        default:
            break;
    }
    return fields;
}

function formattedFieldsClient (questionSet) {
    let fields  = [];

    for (let sIndex = 0; sIndex < questionSet.sections.length; sIndex++) {
        const section = questionSet.sections[sIndex];

        fields.push(new FieldText(`<b>${sIndex + 1} ${section.title}</b> <br> (Section)`));

        if (section.questions.length > 0) {

            for (let qIndex = 0; qIndex < section.questions.length; qIndex ++) {
                const question = questionSet.questions[section.questions[qIndex]];
                const index = `${sIndex + 1}.${qIndex + 1}`;
                const description = question.description?.length > 0 && question.description !== 'description' ? `<br>${question.description} ` : '';

                if (question.config.type === 'label') {
                    fields.push(new FieldText(`<b>${index} ${question.title} - ${description}</b> <br> (Label)`));
                } else {
                    if (question.questions.length === 0) {
                        let questField = formattedQuestion(index, question, questionSet);
                        fields = fields.concat(questField);
                    } else {
                        if (question.title.length > 0) {
                            fields.push(new FieldText(`<b>${index} ${question.title} - ${description}</b> <br> (Question Collection)`));
                        }
                        for (let qqIndex = 0; qqIndex < question.questions.length; qqIndex++) {
                            const subQuestion = questionSet.questions[question.questions[qqIndex]];
                            let questField = formattedQuestion(index, subQuestion, questionSet);
                            fields = fields.concat(questField);
                        }
                    }
                }
            }

        }
    }
    return fields;
}



export {
    formatDate,
    isFunction,
    formattedFields,
    formattedFieldsClient,
};

