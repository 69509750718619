<template>
    <v-popover
        placement="bottom-start"
        popoverClass="select"
        popoverArrowClass="hidden"
        popoverInnerClass="popover-inner tooltip-inner popSelectInner"
    >
        <div class="selectWrapper">
            <button ref="popSelect" class="selectedButton">{{ selectedObject }} <span class="icon icon-chevron-down"></span></button>
        </div>
        <template slot="popover">
            <div
                v-close-popover
                v-for="(option, key) in list"
                class="option"
                @click="emitOption(option)"
                :key="key"
                :style="{ width: popSelectWidth + 'px' }"
            >
                {{ option }}
            </div>
        </template>
    </v-popover>
</template>

<script>
export default {
    name: "selectPopover",
    props: {
        list: {
            type: Array,
            validator: function(value) {
                if (!Array.isArray(value)) {
                    Vue.util.warn("Invalid value: Value should be Array.");
                    return false;
                } else {
                    return true;
                }
            },
            required: true
        },
        initialValue: {
            type: String,
            validator: function(value) {
                if (typeof value !== "string") {
                    Vue.util.warn("Invalid value: Value should be string.");
                    return false;
                } else {
                    return true;
                }
            }
        },
        placeholderText: {
            type: String,
            default: "Select a value"
        },
        disabled: {}
    },
    data() {
        return {
            inputData: "",
            inputFocused: false,
            selectedObject: {},
            arrayOfObjects: [],
            showMenu: false,
            popSelectWidth: 0
        };
    },
    created() {
        this.inputData = this.initialValue !== "" ? this.initialValue : "";
        this.selectedObject = this.initialValue !== "" ? this.inputData : "";
        this.arrayOfObjects = [];
        for (let i = 0; i < this.list.length; i++) {
            this.arrayOfObjects.push(this.list[i]);
        }
    },
    mounted() {
        this.popSelectWidth = this.$refs.popSelect.clientWidth;
    },
    methods: {
        emitOption(value) {
            this.selectedObject = value;
            this.inputData = value;
            this.$emit("input", value);
        }
    },
    computed: {
        inputValue: {
            // getter
            get() {
                return this.inputData;
            },
            // setter
            set(newValue) {
                this.inputData = newValue;
            }
        }
    },
    watch: {
        initialValue(value) {
            this.inputData = value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/all.scss";
.v-popover {
    margin-bottom: 15px;
    .selectWrapper:after {
        border-bottom: 2px solid #002f5f;
        content: "";
        display: block;
        margin-top: -2px;
        position: absolute;
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        width: 0;
    }
    .selectedButton {
        background: white;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border: none;
        font-size: 16px;
        width: 100%;
        text-align: left;
        .icon {
            float: right;
            width: auto;
        }
    }
}
</style>
<style lang="scss">
span.trigger {
    width: 100%;
}
.select {
    &.tooltip[x-placement^="bottom"],
    .tooltip[x-placement^="top"] {
        margin-top: 0;
        margin-bottom: 0;
    }

    .popSelectInner {
        // margin-left:10px;
        padding: 5px 0 !important;
        .option {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}
</style>
