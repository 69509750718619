/* eslint-disable */
/* jshint ignore:start */
import "@/assets/css/animate.css";
import "@/assets/css/fonts.css";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import "@/assets/js/caja.js";
import "vue-awesome/icons";
import App from "./App.vue";
import Icon from "vue-awesome/components/Icon";
import Vue from "vue";
import camelCase from "lodash/camelCase";
import router from "./router/router";
import store from "./store/store";
import upperFirst from "lodash/upperFirst";

import './interceptor.js';

import VTooltip from "v-tooltip";

Vue.use(VTooltip);

Vue.config.productionTip = false;
Vue.component("icon", Icon);

// input component registration
const requireComponent = require.context("@/components/common/inputs", false, /[\w-]\.vue$/);

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);

    const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, "")));

    Vue.component(componentName, componentConfig.default || componentConfig);
});

// eslint-disable-next-line func-names, no-extend-native
String.prototype.base64ToBlob = function (mime) {
    const bstr = atob(this);
    const ab = bstr.s2ab();
    return new Blob([ab], { type: mime });
};

// eslint-disable-next-line func-names, no-extend-native
String.prototype.s2ab = function () {
    const buf = new ArrayBuffer(this.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i !== this.length; ++i) view[i] = this.charCodeAt(i) & 0xFF;
    return buf;
};

//global mixins
Vue.mixin({
    computed: {
        LCPAdmin() {
            return this.$store.state.user.isCollator === true;
        },
        showEditNav() {
            return this.$store.state.show.EditNav === true;
        },
        editMode() {
            return this.$route.name === "editMode";
        },
        showHelp() {
            return this.$store.state.show.HelpSection;
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");

/* jshint ignore:end */
