<template>
    <div id="app">
        <div class="content">
            <router-view />
        </div>
        <footer class="privacy-policy">
            <a href="https://lcpcloud.com/privacy" target="_blank">Privacy policy</a>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'app',
};
</script>

<style lang="scss">
@import 'src/assets/scss/all.scss';
html, body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: #fff;
    font-family: $primaryFont;
    font-size: 14px;
    height: 100%;
}
body {
    overflow-y: scroll;
}
#app {
    background: #fff;
    height: 100%;
    .content {
        height: 100%;
    }
    .privacy-policy {
        bottom: 0;
        color: $lcpWatermelon;
        background: #ffffff;
        border-radius: 5px;
        left: 0;
        padding: 5px;
        position: fixed;
        z-index: 5;
        a {
            color: $lcpWatermelon;
        }
    }
}
</style>
