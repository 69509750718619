import hello from 'hellojs';

window.setTimeout(() => {
    let authResponse = localStorage.getItem('hello');
    let authItem = null;
    if (authResponse && authResponse !== '{}') {
        authItem = JSON.parse(authResponse).localSignIn;
    }
}, 100);

export const HELLOJSLOCALSIGNINPOLICYNAME = 'localSignIn';
export const HELLOJSLCPSIGNINPOLICYNAME = 'lcpSignIn';
export const HELLOJSREGISTERPOLICYNAME = 'signUp';


export const auth = {
    init () {
        this.initSignInSignUp(hello);
        hello.init({
            localSignIn: process.env.VUE_APP_B2C_APPID,
            lcpSignIn: process.env.VUE_APP_B2C_APPID,
            signUp: process.env.VUE_APP_B2C_APPID,
        }, {
            redirect_uri: process.env.VUE_APP_B2C_LOGOUT_URI,
            scope: `openid ${process.env.VUE_APP_B2C_SCOPE}`,
            response_type: 'token id_token',
        });
        this.helloNetwork = [{
            localSignIn: HELLOJSLOCALSIGNINPOLICYNAME,
            lcpSignIn: HELLOJSLCPSIGNINPOLICYNAME,
            signUp: HELLOJSREGISTERPOLICYNAME,
        }];
        this.helloNetwork.localSignIn = HELLOJSLOCALSIGNINPOLICYNAME;
        this.helloNetwork.lcpSignIn = HELLOJSLCPSIGNINPOLICYNAME;
        this.helloNetwork.signUp = HELLOJSREGISTERPOLICYNAME;
        this.loginDisplayType = {
            PopUp: 'popup',
            None: 'none',
            Page: 'page',
        };
    },
    signIn () {
        this.policyLogin(this.helloNetwork.localSignIn, this.loginDisplayType.Page);
    },
    lcpSignIn () {
        this.policyLogin(this.helloNetwork.lcpSignIn, this.loginDisplayType.Page);
    },
    signUp () {
        this.policyLogin(this.helloNetwork.signUp, this.loginDisplayType.Page);
    },
    signOut () {
        if(this.online(this.helloNetwork.localSignIn)) {
            this.policyLogout(this.helloNetwork.localSignIn);
        }
        if(this.online(this.helloNetwork.lcpSignIn)) {
            this.policyLogout(this.helloNetwork.lcpSignIn);
        }

    },
    policyLogin (network, displayType) {
        if (!displayType) {
            // eslint-disable-next-line no-param-reassign
            displayType = 'popup';
        }

        // in case of silent renew, check if the session is still active otherwise ask the user to login again
        if (!this.online(network) && displayType === this.loginDisplayType.None) {
            // eslint-disable-next-line no-alert
            alert('Session expired... please login again', () => {
                this.policyLogin(network, this.loginDisplayType.Page);
            });
            return;
        }

        hello(network)
            .login({
                display: displayType,
                page_uri: process.env.VUE_APP_B2C_LOGOUT_URI,
                state: network
            }, this.log)
            .then(() => {
            }, (e) => {
                if ('Iframe was blocked' in e.error.message) {
                    this.policyLogin(network, this.loginDisplayType.Page);
                    return;
                }
                alert(`Signin error: ${e.error.message}`);
            });
    },
    online (network) {
        // eslint-disable-next-line no-param-reassign
        if (!network) network = this.helloNetwork.localSignIn;
        let thisSession = hello(network).getAuthResponse(network);
        const currentTime = (new Date()).getTime() / 1000;
        return thisSession && thisSession.access_token && thisSession.expires > currentTime;
    },
    setSignInState (signedIn) {
        this.isSignedIn = signedIn;
    },
    policyLogout (network) {
        hello.logout(network, { force: true }).then(() => {
        }, (e) => {
            alert(`Logout error: ${e.error.message}`);
        });

    },
    log (s) {
        if (typeof s.error !== 'undefined' && s.error !== null) {
            if (s.error.code === 'blocked') { // silentrenew(display: none) in case of expired token returns X-frame Options as DENY error
                alert('There was an error in silent renewing the token. Please login again');
            }
        } else {
            this.responseText = JSON.stringify(s, true, 2);
        }
    },
    // eslint-disable-next-line no-shadow
    initSignInSignUp (hello) {
        var authUrl = 'https://' + process.env.VUE_APP_B2C_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_B2C_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/authorize?p=';
        var grantUrl = 'https://' + process.env.VUE_APP_B2C_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_B2C_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/token?p=';
        hello.init({
            localSignIn: {
                name: 'Azure B2C',
                oauth: {
                    version: 2,
                    auth: authUrl + process.env.VUE_APP_B2C_LOCALSIGNIN_POLICYNAME,
                    grant: grantUrl + process.env.VUE_APP_B2C_LOCALSIGNIN_POLICYNAME,
                },
                refresh: true,
                scope_delim: ' ',
                logout () {
                    // get id_token from auth response
                    const idToken = hello(HELLOJSLOCALSIGNINPOLICYNAME).getAuthResponse().id_token;
                    // clearing local storage session
                    hello.utils.store(HELLOJSLOCALSIGNINPOLICYNAME, null);

                    // redirecting to Azure B2C logout URI
                    window.location = 'https://' + process.env.VUE_APP_B2C_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_B2C_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/logout?p=' + process.env.VUE_APP_B2C_LOCALSIGNIN_POLICYNAME + '&id_token_hint=' +
                        idToken + '&post_logout_redirect_uri=' + process.env.VUE_APP_B2C_LOGOUT_URI;
                },
            },
            lcpSignIn: {
                name: 'Azure Active Directory B2C',
                oauth: {
                    version: 2,
                    auth: authUrl + process.env.VUE_APP_B2C_LCPSIGNIN_POLICYNAME,
                    grant: grantUrl + process.env.VUE_APP_B2C_LCPSIGNIN_POLICYNAME,
                },
                refresh: true,
                scope_delim: ' ',
                logout () {
                    // get id_token from auth response
                    const idToken = hello(HELLOJSLCPSIGNINPOLICYNAME).getAuthResponse().id_token;
                    // clearing local storage session
                    hello.utils.store(HELLOJSLCPSIGNINPOLICYNAME, null);

                    // redirecting to Azure B2C logout URI
                    window.location = 'https://' + process.env.VUE_APP_B2C_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_B2C_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/logout?p=' + process.env.VUE_APP_B2C_LCPSIGNIN_POLICYNAME + '&id_token_hint=' +
                    idToken + '&post_logout_redirect_uri=' + process.env.VUE_APP_B2C_LOGOUT_URI;
                },
                // Don't even try submitting via form.
                // This means no POST operations in <=IE9
                form: false,
            },
            signUp: {
                name: 'Azure B2C Sign Up',
                oauth: {
                    version: 2,
                    auth: authUrl + process.env.VUE_APP_B2C_REGISTER_POLICYNAME
                },
                refresh: false,
                scope_delim: ' ',
                logout () {
                    // clearing local storage session
                    hello.utils.store(HELLOJSREGISTERPOLICYNAME, null);
                },
            },
        });
    },
};
