<template>
    <div class="input-bio--div">
        <div v-for="(bl, key) in bioList" :key="key">
            <div class="remove-bio--div" v-show="bioList.length !== 1" :key="key + '-remove'">
                <div class="remove-bio" @click="removeBio(key)"  v-tooltip="'Delete contact'"><span class="icon icon-bin"></span></div>
            </div>
            <div :key="key" class="panel" :ref="'panel' + key">
                <div class="bio-header" @click="collapse('panel' + key, key)">
                    <h2 class="bio-title"><span v-if="bl.name !== ''">{{bl.name}}</span> <span v-if="bl.jobTitle !== '' && bl.name !== ''"> - {{bl.jobTitle}}</span>
                        <span class="icon icon-chevron-down"  :ref="'panel' + key + 'chevron'"></span>
                    </h2>
                </div>
                <div class="bio-content" >
                    <div class="bio-wrapper">
                        <div class="label">Name</div>
                        <div class="input-div" :class="{'focused': inputFocusedName}">
                            <input type="text" class="input-style text" v-model="bl.name" @input="emitValues()" @focus="focusToggle('inputFocusedName')" @blur="!inputFocusedName" placeholder="Name" :disabled="disabled === true"/>
                        </div>
                    </div>
                    <div class="bio-wrapper">
                        <div class="label">Job Title</div>
                        <div class="input-div" :class="{'focused': inputFocusedJob}">
                            <input type="text" class="input-style text" v-model="bl.jobTitle" @input="emitValues()" @focus="focusToggle('inputFocusedJob')" @blur="!inputFocusedJob" placeholder="Job Tile" :disabled="disabled === true"/>
                        </div>
                    </div>
                    <div class="bio-wrapper">
                        <div class="label">Phone Number</div>
                        <div class="input-div" :class="{'focused': inputFocusedPhone}">
                            <input type="text" class="input-style text" v-model="bl.phone" @input="emitValues()" @focus="focusToggle('inputFocusedPhone')" @blur="!inputFocusedPhone" placeholder="Phone" :disabled="disabled === true"/>
                        </div>
                    </div>
                    <div class="bio-wrapper">
                        <div class="label">Mobile Number</div>
                        <div class="input-div" :class="{'focused': inputFocusedMobile}">
                            <input type="text" class="input-style text" v-model="bl.mobile" @input="emitValues()" @focus="focusToggle('inputFocusedMobile')" @blur="!inputFocusedMobile" placeholder="Mobile" :disabled="disabled === true"/>
                        </div>
                    </div>
                    <div class="bio-wrapper">
                        <div class="label">Email Address</div>
                        <div class="input-div" :class="{'focused': inputFocusedMail}">
                            <input type="text" class="input-style text" v-model="bl.mail" @input="emitValues()" @focus="focusToggle('inputFocusedMail')" @blur="!inputFocusedMail" placeholder="Email" :disabled="disabled === true"/>
                        </div>
                    </div>
                    <div class="textarea-bio">
                        <div class="textarea-bio--label">Additional Information</div>
                        <div class="textarea-bio--text">
                            <textarea v-model="bl.bio" @input="emitValues()" class="textarea-style" rows="5" :disabled="disabled === true" @focus="focusToggle('inputFocusedTextArea')"></textarea>
                            <character-count :limit="characterLimit" :charLength="bl.bio.length" :key="key"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-bio--div" :key="key + '-add'" v-show="key === (bioList.length-1)">
                <button class="add-bio add" @click="addBio()" title="Add additional bio" aria-label="Add additional bio">
                    <span class="icon icon-plus"></span>
                </button>
                <div class="add-bio--text add">Additional Bio</div>
            </div>
        </div>
        <modal v-if="showModal" @close="closeModal()" :config="modalConfig"/>
    </div>
</template>

<script>
import characterCount from '@/components/common/layout/characterCount';
import modal from '@/components/common/layout/Modal';

export default {
    name: 'bioInput',
    components: {characterCount, modal},
    props: ['initialValue',  'disabled', 'options'],
    data () {
        return {
            bioList: [{
                name: '',
                jobTitle: '',
                phone: '',
                mobile: '',
                mail: '',
                bio: ''
            }],
            inputFocusedJob: false,
            inputFocusedMail: false,
            inputFocusedName: false,
            inputFocusedPhone: false,
            inputFocusedMobile: false,
            showModal: false
        };
    },
    mounted () {
        this.bioList = this.initialValue !== '' && this.initialValue !== undefined ? this.initialValue : this.bioList;
        this.deleteKey = null;
        this.modalConfig = {
            title: 'Do you want to delete?',
            description: 'Are you sure you wish to delete this entry?',
            inputs: {
                type: 'confirm',
                choices: [{
                    text: 'Delete answer',
                    icon: 'check',
                    action: this.deleteCall
                },
                {
                    text: 'Cancel',
                    icon: 'x',
                    action: 'closeModal'
                }]
            }
        };
    },
    methods: {
        addBio () {
            for (let i = 0; i < this.bioList.length; i++) {
                this.$refs['panel' + i][0].style.height = '40px';
                this.$refs['panel' + i + 'chevron'][0].style.transform = 'rotate(0deg)';
            }
            this.bioList.push({
                name: '',
                jobTitle: '',
                phone: '',
                mobile: '',
                mail: '',
                bio: ''
            });
        },
        closeModal () {
            this.deleteKey = null;
            this.showModal = false;
        },
        deleteCall () {
            this.bioList.splice(this.deleteKey, 1);
            this.deleteKey = null;
            this.showModal = false;
            this.$emit('input', this.bioList);
        },
        removeBio (key) {
            this.deleteKey = key;
            this.showModal = true;
        },
        emitValues () {
            for (let i = 0; i < this.bioList.length; i++) {
                this.bioList[i].name !== '' && this.bioList[i].jobTitle !== '' ? this.$emit('input', this.bioList) : '';
            }
        },
        collapse (content, key) {
            if (this.$refs[content][0].style.height === '40px') {
                this.$refs[content][0].style.height = '460px';
                this.$refs[content + 'chevron'][0].style.transform = 'rotate(180deg)';
                for (let i = 0; i < this.bioList.length; i++) {
                    if (i !== key) {
                        this.$refs['panel' + i][0].style.height = '40px';
                        this.$refs['panel' + i + 'chevron'][0].style.transform = 'rotate(0deg)';
                    }
                }
            } else {
                this.$refs[content][0].style.height = '40px';
                this.$refs[content + 'chevron'][0].style.transform = 'rotate(0deg)';
            }
        },
        focusToggle (input) {
            input === 'inputFocusedMail' ? this.inputFocusedMail = !this.inputFocusedMail : this.inputFocusedMail = false;
            input === 'inputFocusedName' ? this.inputFocusedName = !this.inputFocusedName : this.inputFocusedName = false;
            input === 'inputFocusedJob' ? this.inputFocusedJob = !this.inputFocusedJob : this.inputFocusedJob = false;
            input === 'inputFocusedPhone' ? this.inputFocusedPhone = !this.inputFocusedPhone : this.inputFocusedPhone = false;
            input === 'inputFocusedMobile' ? this.inputFocusedMobile = !this.inputFocusedMobile : this.inputFocusedMobile = false;
            if (input === 'inputFocusedTextArea') {
                this.inputFocusedMail = false;
                this.inputFocusedName = false;
                this.inputFocusedJob = false;
                this.inputFocusedPhone = false;
                this.inputFocusedMobile = false;
            }
        }
    },
    computed: {
        characterLimit () {
            if(this.options !== null && typeof this.options !=="undefined" && this.options.hasOwnProperty("limit")) {
                return this.options.limit;
            } else {
                return 150;
            }
        }
    },
    watch: {
        initialValue (value) {
            this.bioList = value !== '' ? this.bioList = value : '' ;
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
    .panel {
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        height: 460px;
        margin-bottom: 20px;
        overflow: hidden;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        .bio-header {
            color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
            display: inline-block;
            height: 40px;
            padding: 10px;
            width:100%;
            .bio-title {
                display: inline-block;
                text-align: center;
                width: 100%;
            }
            .icon-chevron-down {
                float: right;
                transform: rotate(180deg);
            }
        }
        .bio-content {
            height:300px;
            padding: 0 20px 0 20px;
        }
    }
    .input-bio--div {
        display: block;
        position: relative;
        width: 50%;
        .textarea-bio {
            display: block;
            margin-bottom: 30px;
            position: relative;
            .textarea-bio--icon {
                display: inline-table;
                margin-right: 10px;
                vertical-align: top;
            }
            .textarea-bio--label  {
                margin-bottom: 10px;
                margin-top: 20px;
            }
            .textarea-bio--text {
                display: inline;
                .textarea-style {
                    background: #fff;
                    border-radius: 5px;
                    border: 1px solid rgba(0,0,0,0.12);
                    box-shadow: 0;
                    height: 100px;
                    padding: 10px;
                    resize: none;
                    transition-duration: 0.5s;
                    transition-property: all;
                    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                    width: 100%;
                }
            }
        }
        .bio-wrapper {
            display: block;
            margin-bottom: 10px;
            position: relative;
            div {
                display: inline-block;
                position: relative;
            }
            .label {
                width: 20%;
            }
            .input-style {
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,0.12);
                display: block;
                padding-right: 0;
                padding: 10px;
                position: relative;
                width: 100%;
                &.text {
                    border-bottom: 1px solid rgba(0,0,0,0.12);
                    font-size: 16px;
                    padding-bottom: 5px;
                    text-align: left;
                }
            }
            .input-div {
                width: 80%;
                &:after {
                    border-bottom: 2px solid $lcpDarkBlue;
                    content: '';
                    display: block;
                    margin-top: -2px;
                    position: absolute;
                    transition: all 0.3s cubic-bezier(.4,0,.2,1);
                    width: 0;
                }
                &.focused {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
        .add-bio--div {
            display: inline-block;
            position: relative;
            width: 100%;
            .add {
                float: right;
            }
            .add-bio {
                border-radius: 2px;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
                color: #fff;
                cursor: pointer;
                display: block;
                float: right;
                padding: 10px;
                position: relative;
                text-align: center;
                z-index: 1;
            }
            .add-bio--text {
                color: $grey;
                cursor: pointer;
                display: block;
                font-size: 18px;
                font-size: 600;
                padding: 12px;
                position: relative;
                text-align: center;
            }
        }
        .remove-bio--div {
            cursor: pointer;
            position:relative;
            width: 100%;
            z-index: 1;
            .remove-bio {
                color: red;
                float: right;
                margin-left:18px;
                position: relative;
                .icon {
                    font-size: 1.5rem;
                }
            }
        }
    }
    textarea:disabled {
        background-color:rgb(235, 235, 228)!important;
    }
</style>
