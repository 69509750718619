export default {
    namespaced: true,
    state: {
        organisations: []
    },
    actions: {},
    mutations: {
        updateOrganisations(state, orgs) {
            state.organisations = orgs;
        }
    }
};
