<template>
    <div class="wrapper" :class="[this.$store.state.currentTheme]">
        <div class="modal animated ee fadeInUp">
            <div class="close" @click="closeModal()">
                <span class="icon icon-x"></span>
            </div>
            <div class="intro">
                <div class="container">
                    <h2>{{ config.title }}</h2>
                    <p>{{config.description}}</p>
                    <div id="inputsWrapper" v-if="config.inputs !== null">
                        <div v-if="config.inputs.type='confirm'">
                            <button class="btn btn-confirm" @click="fireAction(choice.action)" v-for="choice in config.inputs.choices" :key="choice.text">
                                {{choice.text}}<span class="icon" :class="'icon-' + choice.icon" v-if="choice.icon !== null"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: [ 'config' ],
    mounted () {
        this.data = this.config;
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        fireAction (action) {
            typeof action === 'function' ? action() : action === 'closeModal' ? this.$emit('close') : '';
        },
    }
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/all.scss';
.wrapper {
    background-color: rgba(0,0,0,.3);
    bottom:0;
    left:0;
    position: fixed;
    right:0;
    top:0;
    z-index: 101;
    .modal {
        bottom: 0;
        color: white;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 102;
        @include responsive (mobile) {
            height: 100vh;
            left: 0;
            top: 0;
            width: 100vw;
        }
        height: auto;
        min-height: 100px;
        padding-bottom: 2rem;
        @include responsive (mobile) {
            max-height: 100vh;
        }
        display: flex;
        flex-direction: column;
        .close {
            cursor: pointer;
            font-size: 30px;
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 2;
        }
    }
    .content.container {
        padding-top: 2rem;
        @include responsive(mobile) {
            padding-top: 10rem;
        }
    }
    .content {
        flex: 1;
        text-align: center;
    }
    .intro {
        position: relative;
        @include responsive (mobile) {
            position: fixed;
            width: 100%;
            z-index: 9;
        }
        @include responsive (tablet) {
            height: 130px;
        }

        h2 {
            font-size: 2rem;
            font-weight: 400;
            padding: 20px;
            @include responsive (mobile) {
                font-size: 1.5rem;
                padding-left: 2.5rem;
            }
        }
        p {
            padding: 0 20px;
        }

        .container {
            position: relative;
            z-index: 1;
            @include responsive (mobile) {
                padding: 1rem 1.5rem;
            }
            #inputsWrapper {
                padding: 20px 20px 0;
                .btn{
                    background: white;
                    border-radius: 3px;
                    color: grey;
                    cursor: pointer;
                    display: inline-block;
                    margin-right: 20px;
                    padding: 10px;
                    .icon {
                        float: right;
                        margin-left: 10px;
                    }
                    &:hover {
                        -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.3);
                        -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.3);
                        box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.3);
                    }
                }
            }
        }
        padding-bottom: 0;
    }
    @keyframes growIn {
        from { transform: scale(1.3); opacity: 0; }
        to { transform: scale(1); opacity: 1; }
    }
    .back {
        color: white;
        left: 1.5rem;
        position: absolute;
        top: 1rem;
        transform: scale(1.75);
        z-index: 11;
        &:hover {
            cursor: pointer;
        }
        @include responsive (mobile) {
            position: fixed;
        }
    }
    .title {
        font-size: 1.4rem;
        line-height: 1.5;
        padding: 0.5rem 1rem;
        position: absolute;
        right: 2.5vw;
        top: 12rem;
        width: 400px;
        @include responsive (sd) {
            display: inline-block;
            font-size: 1.2rem;
            left: 50%;
            padding: 0 2rem;
            position: relative;
            right: auto;
            top: 0;
            transform: translateX(-50%);
            width: auto;
        }
        @include responsive (mobile) {
            margin-bottom: 1rem;
            position: relative;
            right: auto;
            top: 0;
        }
    }
}
</style>
