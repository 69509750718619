<template>
    <div class="input-date--div" :class="[narrow ? '' : 'deep', whiteIcons ? 'white-icons' : '' ]">
        <datepicker
            :calendar-button-icon="'icon icon-calendar'"
            :calendar-button="true"
            :calendar-class="lastIndex"
            :clear-button-icon="'icon icon-bin'"
            :clear-button="true"
            :disabled="disabled"
            :id="objId"
            :key="objId"
            :placeholder="placeholder"
            :use-utc="true"
            :value="inputData"
            @selected="emitValue($event)"
            @cleared="emitValue($event)"
            />
    </div>
</template>

<script>
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import { formatDate } from '@/assets/js/utils.js';

export default {
    name: 'dateInput',
    props: {
        initialValue: {

        },
        lastDate: {
            type: Boolean,
            validator: function (value) {
                if (typeof value !== 'boolean') {
                    Vue.util.warn('Invalid value: Value should be boolean.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        placeholder: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            },
            default: 'Select Date'
        },
        disabled: {},
        objId: {},
        narrow: {
            type: Boolean,
            default: false
        },
        whiteIcons: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Datepicker
    },
    data () {
        return {
            inputData: ''
        };
    },
    created () {
        if (this.initialValue !== '' &&  this.initialValue !== undefined) {
            this.inputData = this.initialValue._ts > 0 ? new Date(this.initialValue._ts) : '';
        } else {
            this.inputData = '';
        }
    },
    methods: {
        emitValue (event) {
            this.inputData = event !== null ? new Date(event).getTime() : '';
            this.$emit('input', this.inputData);
        },
        getDate (date, format) {
            return formatDate(date, format);
        }
    },
    computed: {
        inputValue: {
            // getter
            get () {
                return this.inputDate;
            },
            // setter
            set (newValue) {
                this.inputDate = newValue;
            }
        },
        lastIndex () {
            return this.lastDate ? "last-date" : '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
    .input-date--div {
        color: rgba(0, 0, 0, 0.6);
        display: inline-block;
        position: relative;
        .input-style {
            display: inline-block;
            position: relative;
            &.text {
                border-bottom: 1px solid rgba(0,0,0,0.12);
                border: none;
                display: block;
                font-size: 16px;
                padding-bottom: 5px;
                text-align: left;
            }
        }
        &:after {
            border-bottom: 2px solid $lcpWatermelon;
            content: '';
            display: block;
            margin-top: -2px;
            position: absolute;
            transition: all 0.3s cubic-bezier(.4,0,.2,1);
            width: 0;
        }
        &.focused {
            &:after {
                width: 90%;
            }
        }
        .vdp-datepicker ::v-deep input {
            padding: 2px 5px;
        }
    }
    .deep .vdp-datepicker ::v-deep input {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        cursor: pointer;
        padding: 10px 5px;
    }
    .white-icons .vdp-datepicker ::v-deep .icon {
        color: #fff!important;
    }
</style>
