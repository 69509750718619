/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/router.js";
import { auth } from "@/services/authentication.js";
import authStore from "@/store/modules/auth";
import showStore from "@/store/modules/show";
import data from "@/store/modules/data";

import dayjs from 'dayjs';
import uts from 'dayjs/plugin/utc';
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(uts);
dayjs.extend(advancedFormat)

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: authStore,
        data: data,
        show: showStore
    },
    state: {
        allAnswers: null,
        allOrganisations: null,
        allQuestions: null,
        answerFilterArray: [],
        answers: [],
        completions: {},
        currentTheme: "theme-1",
        editData: {},
        organisation: {},
        pendingAnswers: [],
        questionFilterArray: [],
        questionSet: {},
        questionSetSections: [],
        questionSetUnasweredSections: [],
        questionnaireFilterArray: [],
        selectedOrganisation: "",
        selectedSectionKey: null,
        user: {},
        usersQuestionnaires: []
    },
    getters: {},
    mutations: {
        addPendingAnswers(state, answer) {
            state.pendingAnswers.push(answer);
        },
        updatePendingAnswers(state, answer) {
            Vue.set(state.pendingAnswers, answer.key, answer.data);
        },
        updateAnswers(state, answer) {
            Vue.set(state.answers, answer.key, answer.data);
        },
        resetPendingAnswers(state) {
            state.pendingAnswers = [];
        },
        setQuestionSetSections(state, responseData) {
            state.questionSetSections = responseData;
        },
        addQuestion(state, response) {
            state.questionSet.sections[state.selectedSectionKey].questions.push(state.editData.newQuestion.id);
            state.questionSet.questions[state.editData.newQuestion.id] = state.editData.newQuestion;
        },
        addQuestionByPosition(state, index) {
            state.questionSet.sections[state.selectedSectionKey].questions.splice(index, 0, state.editData.newQuestion.id);
            state.questionSet.questions[state.editData.newQuestion.id] = state.editData.newQuestion;
        },
        addTableQuestion(state, question) {
            Vue.set(state.questionSet.questions, question.id, question);
        },
        updateQuestionnaireInfo(state, response) {
            state.questionSet.info = response;
        },
        updateCollectionQuestions(state, questions) {
            state.questionSet.questions[state.editData.newQuestion.id].questions = questions;
        },
        updateSectionQuestionsOrder(state, response) {
            state.questionSet.sections[state.selectedSectionKey].questions = response;
        },
        updateSectionsOrder(state, response) {
            state.questionSet.sections = response;
        },
        addSection(state, response) {
            state.questionSet.sections.push(state.editData.newSection);
        },
        loadQuestionSet(state, responseData) {
            state.questionSet = responseData;
        },
        loadUnasweredQuestions(state, responseData) {
            state.questionSetUnasweredSections = responseData;
        },
        loadAllQuestions(state, responseData) {
            state.allQuestions = responseData.response;
        },
        loadAllAnswers(state, responseData) {
            state.allAnswers = responseData.response;
        },
        updateSelectedSectionKey(state, responseData) {
            state.selectedSectionKey = responseData;
        },
        updateUser(state, responseData) {
            state.user.username = responseData.username;
            state.user.isCollator = responseData.isCollator;
            state.user.organisation = responseData.organisation;
        },
        updateQuestionSets(state, responseData) {
            state.usersQuestionnaires = responseData;
        },
        addQuestionnaire(state, questionnaire) {
            state.usersQuestionnaires.push(questionnaire);
            state.organisation.questionnaires.push(questionnaire.info.questionnaireId)
        },
        updateUserOrganisation(state, organisation) {
            state.user.organisation = organisation;
        },
        updateOrganisation(state, responseData) {
            state.organisation.name = responseData.name;
            state.organisation.type = responseData.type;
            state.organisation.admin = responseData.admin;
            state.organisation.id = responseData.id;
            state.organisation.questionnaires = responseData.questionnaires;
            state.organisation.readOnly = responseData.hasOwnProperty('readOnly') ? responseData.readOnly : true;
        },
        updateAllOrganisation(state, responseData) {
            state.allOrganisations = responseData;
        },
        updateQuestions(state, responseData) {
            state.questions = responseData;
        },
        updateEditData(state, responseData) {
            state.editData = responseData;
        },
        updateEditDataNewQuestion(state, responseData) {
            state.editData.newQuestion = responseData;
        },
        updateEditDataSection(state, responseData) {
            state.editData.section = responseData;
        },
        updateSection(state) {
            Vue.set(state.questionSet.sections, state.selectedSectionKey, state.editData.section);
        },
        updateEditDataNewSection(state, responseData) {
            state.editData.newSection = responseData;
        },
        updateSelectedQuestionnaire(state, responseData) {
            state.selectedQuestionnaire = responseData;
        },
        updateAnswerSet(state, responseData) {
            state.answers = responseData;
        },
        updateQuestionnaireFilterArray(state, responseData) {
            state.questionnaireFilterArray = responseData;
        },
        updateQuestionFilterArray(state, responseData) {
            state.questionFilterArray = responseData;
        },
        updateAnswerFilterArray(state, responseData) {
            state.answerFilterArray = responseData;
        },
        updateSelectedOrganisation(state, responseData) {
            state.selectedOrganisation = responseData;
        },
        toggleSideNav(state) {
            state.sideNavShow = !state.sideNavShow;
        },
        updateCompletions(state, responseData) {
            state.completions = responseData;
        },
        updateOrganisations(state, responseData) {
            state.allOrganisations = responseData;
            state.questionSet.organisations = responseData;
        }
    },
    actions: {
        async getUser({ commit, dispatch, state }) {
            let response = await axios.post(`${process.env.VUE_APP_API}/api/Users`);
            if (response) {
                commit("updateUser", response.data);
                if (!state.user.organisation) {
                    router.push({ path: "/approval" });
                } else {
                    let org = await axios.post(`${process.env.VUE_APP_API}/api/QueryOrganisations`, { id: state.user.organisation });
                    commit("updateOrganisation", org.data);
                    await dispatch("getUserQuestionnaires");
                }
            }
        },
        async getUserQuestionnaires({ commit, state }) {
            let response = await axios.post(`${process.env.VUE_APP_API}/api/QueryQuestionnaire`, { id: state.organisation.questionnaires });
            commit("updateQuestionSets", response.data.map(i => i.questionnaire));
        },
        async getQuestionnaireOrganisations({ state }, questionnaireId) {
            if (state.user.isCollator && state.organisation.type === "collator") {
                return (await axios.post(`${process.env.VUE_APP_API}/api/QuestionnaireOrganisations`, {
                    questionnaireId: questionnaireId
                })).data;
            } else {
                return [state.organisation];
            }
        },
        async updateQuestionnaire({ state }) {
            let questionnaire = {
                id: state.questionSet.info.questionnaireId,
                questionnaire: state.questionSet
            };
            await axios.post(`${process.env.VUE_APP_API}/api/UpdateQuestionnaire`, questionnaire);
        },
        async createQuestionnaire({ state, commit }, questionnaire) {
            const data = {questionnaire: questionnaire, organisationId: state.organisation.id};

            await axios.post(`${process.env.VUE_APP_API}/api/AddQuestionnaire`, data).then( () => {
                commit("addQuestionnaire", questionnaire.questionnaire);
            });
        },
        async loadQuestions({ state, commit, dispatch }, request) {
            state.usersQuestionnaires.length === 0 ? await dispatch("getUser") : "";
            let questionnaire = state.usersQuestionnaires.find(x => x.info.questionnaireId === request);
            commit("loadQuestionSet", questionnaire);
            commit("setQuestionSetSections", questionnaire.sections);
        },
        async updateSelectedSectionKey({ state, commit }, key) {
            if (key != null & key.length > 0) {
                let requestQuestions = [];
                let response = { data: [] };

                for (let sectionKey = 0; sectionKey < key.length; sectionKey += 1) {
                    for (let z = 0; z < state.questionSet.sections[key[sectionKey]].questions.length; z++) {
                        let qId = state.questionSet.sections[key[sectionKey]].questions[z];
                        requestQuestions.push(qId);
                        if (state.questionSet.questions[qId].questions.length > 0) {
                            //  Looping through "question-collection" questions
                            for (let q = 0; q < state.questionSet.questions[qId].questions.length; q++) {
                                let qCollectionId = state.questionSet.questions[qId].questions[q];

                                // check for table questions
                                if (state.questionSet.questions[qCollectionId].config.rowsConfig !== undefined) {
                                    for (let r in state.questionSet.questions[qCollectionId].config.rowsConfig) {
                                        for (let q = 0; q < state.questionSet.questions[qCollectionId].config.rowsConfig[r].length; q++) {
                                            if (!requestQuestions.includes(state.questionSet.questions[qCollectionId].config.rowsConfig[r][q])) {
                                                requestQuestions.push(state.questionSet.questions[qCollectionId].config.rowsConfig[r][q]);
                                            }
                                        }
                                    }
                                }

                                requestQuestions.push(state.questionSet.questions[qId].questions[q]);
                            }
                        }
                        if (
                            state.questionSet.questions[qId].config.conditional !== undefined &&
                            state.questionSet.questions[qId].config.conditional.length > 0
                        ) {
                            for (let q = 0; q < state.questionSet.questions[qId].config.conditional.length; q++) {
                                if (!requestQuestions.includes(state.questionSet.questions[qId].config.conditional[q].id)) {
                                    requestQuestions.push(state.questionSet.questions[qId].config.conditional[q].id);
                                }
                            }
                        }
                        if (
                            state.questionSet.questions[qId].config.rconditional !== undefined &&
                            state.questionSet.questions[qId].config.rconditional.length > 0
                        ) {
                            for (let q = 0; q < state.questionSet.questions[qId].config.rconditional.length; q++) {
                                if (!requestQuestions.includes(state.questionSet.questions[qId].config.rconditional[q].id)) {
                                    requestQuestions.push(state.questionSet.questions[qId].config.rconditional[q].id);
                                }
                            }
                        }
                        if (state.questionSet.questions[qId].config.rowsConfig !== undefined) {
                            for (let r in state.questionSet.questions[qId].config.rowsConfig) {
                                for (let q = 0; q < state.questionSet.questions[qId].config.rowsConfig[r].length; q++) {
                                    if (!requestQuestions.includes(state.questionSet.questions[qId].config.rowsConfig[r][q])) {
                                        requestQuestions.push(state.questionSet.questions[qId].config.rowsConfig[r][q]);
                                    }
                                }
                            }
                        }
                    }
                }

                if (requestQuestions.length > 0) {
                    if (state.user.isCollator) {
                        class AnswerQuerySetup {
                            constructor(questionnaireId, organisationId, questionId, userId) {
                                this.questionnaireId = questionnaireId;
                                this.organisationId = organisationId;
                                this.questionId = questionId;
                            }
                        }

                        response = await axios.post(
                            `${process.env.VUE_APP_API}/api/QuestionnaireAnswers`,
                            new AnswerQuerySetup(
                                state.questionSet.info.questionnaireId,
                                state.data.organisations.map(o => o.id),
                                requestQuestions
                            )
                        );

                    } else {
                        let data = {
                            questionnaireId: state.questionSet.info.questionnaireId,
                            organisationId: state.user.organisation,
                            questionId: requestQuestions,
                        };
                        response = await axios.post(`${process.env.VUE_APP_API}/api/QueryAnswers`, data);
                    }

                    if (response.data.length > 0) {
                        await commit("updateAnswerSet", response.data.map(a => ({
                            history: a.history,
                            id: a.id,
                            organisationId: a.organisationId,
                            questionId: a.questionId,
                            questionnaireId: a.questionnaireId,
                            _ts: a._ts
                        })));
                    }
                }
            }
            await commit("updateSelectedSectionKey", key[0]);
        },
        async updateAnswers({ state, commit, dispatch }) {
            let data = {
                questionnaireId: state.questionSet.info.questionnaireId,
                organisationId: state.organisation.id,
                answers: state.pendingAnswers,
                _ts: dayjs().utc().format("X")
            };
            await axios.post(`${process.env.VUE_APP_API}/api/UpdateAnswers`, data);
            commit("resetPendingAnswers");
            await dispatch("updateSelectedSectionKey", [state.selectedSectionKey]);
        },
        login({ state }) {
            return auth.signInSignUp();
        },
        logout({ state }) {
            auth.init();
            return auth.signOut();
        }
    }
});
