<template>
    <div class="input-upload--div">
        <div class="uploadWrapper">
            <input
                :disabled="disabled"
                @change="upload($event.target.files)"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                class="input-style upload"
                type="file"
                />
            <template v-if="loading">
                <span style="color: #e93f6f" >Uploading</span><pulse-loader :loading="loading" color="#e93f6f" size="5px"/>
            </template>
        </div>
        <p v-if="filePath!=''">
            You have uploaded <a :href="filePath" download>{{fileName}}</a>.  To change this file use the button above to upload a different one.
        </p>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import axios from 'axios';

export default {
    name: 'uploadInput',
    props: {
        initialValue: {
            type: [String, Object]
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PulseLoader
    },
    data () {
        return {
            filePath: '',
            fileName: '',
            loading: false
        };
    },
    mounted () {
        if (this.initialValue) {
            this.filePath ='https://insightsiteaj6prdst.blob.core.windows.net/file-uploads/'+ this.initialValue.downloadName;
            this.fileName = this.initialValue.fileName;
        }
    },
    methods: {
        getBase64 (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        upload (formData) {
            this.loading = true;
            this.getBase64(formData[0]).then((d) => {
                let req = {
                    fileData: d,
                    fileName: formData[0].name
                };
                this.uploadFile(req);
            });
        },
        uploadFile(req) {
            const downloadName = `${this.$store.state.organisation.id}_${req.fileName}`;
            const url = `${process.env.VUE_APP_API}/api/UploadFile`;

            let apidata = {
                fileName: downloadName,
                fileData: req.fileData,
            };

            axios.post( url, apidata).then((response) => {
                    if (response.data === 'success') {
                        this.filePath = `https://insightsiteaj6prdst.blob.core.windows.net/file-uploads/${downloadName}`;
                        this.$emit('input', {downloadName, fileName: req.fileName});
                        this.fileName = req.fileName;
                        this.loading = false;
                    }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
    .input-upload--div {
        position: relative;
        width: inherit;
        margin-top: 2rem;
        margin-left: 0.3rem;
        .uploadWrapper {
            display: flex;
            margin-bottom: 20px;
            .v-spinner {
                margin-left: 5px;
            }
        }
        .file-button {
            display: inline-block;
            background: $lcpWatermelon;
            color: white;
            padding: 12px;
            border-radius: 2px;
            z-index: 1;
            cursor: pointer;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
        }
        .file-name {
            font-size: 1rem;
            font-weight: 500;
            display: inline-block;
            color: $lcpWatermelon;
            margin-left: 20px;
        }
        .input-style::-webkit-file-upload-button {
            visibility: hidden;
        }
        .input-style {
            position: relative;
            &.upload {
                margin: 10px 0;
                color: $lcpWatermelon;
            }
        }
        .input-style::before {
            content: 'Choose file';
            background: $lcpWatermelon;
            border-radius: 2px;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
            color: white;
            cursor: pointer;
            display: inline-block;
            white-space: nowrap;
            padding: 5px;
            z-index: 1;
            -webkit-user-select: none;
        }
        .input-style:hover::before {
            border-color: black;
        }
    }
</style>
