import { render, staticRenderFns } from "./textInput.vue?vue&type=template&id=00637dc8&scoped=true"
import script from "./textInput.vue?vue&type=script&lang=js"
export * from "./textInput.vue?vue&type=script&lang=js"
import style0 from "./textInput.vue?vue&type=style&index=0&id=00637dc8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00637dc8",
  null
  
)

export default component.exports