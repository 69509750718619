<template>
    <div class="character-limit">
        characters left: <span :class="limitColor">{{ limit - charLength }}</span>
    </div>
</template>

<script>
export default {
    name: "characterCount",
    props: ["limit", "charLength"],
    data() {
        return {
            warnLimit: this.limit - 10
        };
    },
    computed: {
        limitColor() {
            let result = "";
            if (this.charLength === this.limit) {
                result = "limit";
            } else if (this.charLength > this.warnLimit && this.charLength < this.limit) {
                result = "warn";
            }
            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";
.character-limit {
    color: $grey;
    font-size: 14px;
    margin-top: 5px;
    text-align: right;
    .warn {
        color: orange;
        font-weight: bold;
    }
    .limit {
        color: red;
        font-weight: bold;
    }
}
</style>
