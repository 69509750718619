<template>
    <div class="input-wrapper" :class="{'full-width': fullWidth}">
        <div class="input-text--div" :class="{'focused': inputFocused, 'full-width': fullWidth, 'underline-override' : action !== undefined}" >
            <input
                :class="{'full-width': fullWidth, 'action-button' : action !== undefined, 'error': showError }"
                :disabled="disabled"
                :maxlength="characterLimit"
                :placeholder="placeholder"
                :type="type"
                @blur="inputFocused = false"
                @focus="inputFocused = true"
                @input="emitValue($event.target.value)"
                class="input-style text"
                v-model.trim="inputValue"
                v-on:keyup.enter="$emit('enter-clicked', inputValue)"
                />
             <div class="action-button">
                <div v-if="action !== undefined" class="btn btn-theme" @click="$emit('enter-clicked', inputValue)">{{action.text}}</div>
            </div>
        </div>
        <character-count v-show="!disableCharCount" :limit="characterLimit" :charLength="inputValue.length"/>
        <p class="error-message" v-if="showError">{{ errorMessage }}</p>
    </div>
</template>

<script>
import Vue from 'vue';
import characterCount from '@/components/common/layout/characterCount';
export default {
    name: 'textInput',
    components: {characterCount},
    props: {
        type: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            },
            default: 'text'
        },
        placeholder: {
            type: String,
            default: 'Enter text',
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        initialValue: {
            type: [String, Object],
            default: ''
        },
        options: {
            type: Object,
            default: function () {
                180;
                false;
            },
            validator: function (value) {
                if (typeof value !== 'object') {
                    Vue.util.warn('Invalid value: Value should be object.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        disableCharCount: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object
        },
        showError: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: 'Invalid input. Please ensure the value is correct and try again'
        }
    },
    data () {
        return {
            inputData: '',
            inputFocused: false
        };
    },
    created () {
        this.inputData = Object.keys(this.initialValue).length !== 0 ? this.initialValue.text : '';
    },
    methods: {
        emitValue (val) {
            this.$emit('input', val);
        }
    },
    computed: {
        inputValue: {
            // getter
            get () {
                return this.inputData;
            },
            // setter
            set (newValue) {
                this.inputData = newValue;
            }
        },
        characterLimit () {
            if (this.options !== null && typeof this.options !=="undefined" && this.options.hasOwnProperty("characterLimit")) {
                return this.options.characterLimit;
            } else {
                return 180;
            }
        }
    },
    watch: {
        initialValue () {
            this.inputData = Object.keys(this.initialValue).length !== 0 ? this.initialValue.text : '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/all.scss';
    .input-wrapper {
        width: 50%;
        &.full-width {
            width: 100%;
        }
        .input-text--div {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            display: inline-block;
            position: relative;
            width: 100%;
            .input-style {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                padding: 10px 0 10px 10px;
                position: relative;
                width: 100%;
                &.action-button {
                    padding-right: 60px;
                }
                &.text {
                    border-bottom: 1px solid rgba(0,0,0,0.12);
                    border: none;
                    font-size: 16px;
                    text-align: left;
                }
                &.full-width {
                    width: 100%;
                }
                &.error {
                    border-bottom: 1px solid red;
                }
            }
            &.underline-override {
                height: 40px;
                &:after {
                    position: absolute;
                    bottom: 0;
                }
            }
            &:after {
                content: '';
                display: block;
                margin-top: -2px;
                position: absolute;
                transition: all 0.3s cubic-bezier(.4,0,.2,1);
                width: 0;
            }
            &.focused {
                &:after {
                    width: 100%;
                }
            }
            .action-button {
                position: absolute;
                right: 0;
                top: 0px;
                width: 60px;
                .btn {
                    border-radius: 0 2px 2px 0;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                    color: white;
                    cursor: pointer;
                    display: block;
                    padding: 11px;
                    position: relative;
                    text-align: center;
                    z-index: 1;
                }
            }
        }
        .error-message {
            color: red;
            margin: 10px 0;
        }
    }
</style>
